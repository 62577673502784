import { Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { Currency } from '@shared/cores/types';
import { getShortCurrencySymbol } from '@shared/cores/types/Currency';

export interface CurrencyInputProps extends Omit<InputProps, 'onChange' | 'value'> {
    currency?: Currency;
    onChange?: (value: number) => void;
    value?: number;
    isFreeTier?: boolean;
}

export const CurrencyInput: FC<CurrencyInputProps> = ({
    currency = 'USD',
    type = 'number',
    value,
    onChange,
    isFreeTier = false,
    ...inputProps
}) => {
    const [input, setInput] = useState(
        value !== undefined ? parseFloat(value.toString()).toFixed(2) : undefined,
    );

    useEffect(() => {
        if (onChange) {
            onChange(input ? parseFloat(parseFloat(input).toFixed(2)) : NaN);
        }
    }, [input]);

    return (
        <InputGroup>
            <InputLeftElement
                pointerEvents="none"
                color="gray.400"
                children={getShortCurrencySymbol(currency)}
            />
            <Input
                textAlign="right"
                type={type}
                value={input}
                onChange={(e) => {
                    setInput(e.target.value);
                }}
                onBlur={(e) => {
                    const valueAsNum = parseFloat(e.target.value.toString());
                    if (valueAsNum < 0 || Number.isNaN(valueAsNum)) {
                        setInput((0).toFixed(2));
                    } else {
                        setInput(valueAsNum.toFixed(2));
                    }
                }}
                isReadOnly={isFreeTier}
                {...inputProps}
            />
        </InputGroup>
    );
};
