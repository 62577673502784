import {RawImportedDataRequestDto, ScenarioAdFormatResponseDto,} from '@api-clients/media-plan';
import {AsyncCard, Loading} from '@components/atoms';
import {useAppContextHelper} from '@hooks/_contexts';
import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "@redux";
import {useApiConfiguration} from "@hooks/configuration";
import {
    proveCampaignIsError,
    proveCampaignIsPending,
    proveSelectedCampaign
} from "@redux/slices/prove/campaign/selectors";
import {ErrorCard} from "@components/molecules/errorCard/ErrorCard";
import {
    proveAdFormatIsError,
    proveAdFormatIsPending,
    proveAvailableAdFormats
} from "@redux/slices/prove/adFormat/selectors";
import {globalCampaignIsPending, selectedGlobalCampaign} from "@redux/slices/global/campaign/selectors";
import {
    proveAvailableLineItems,
    proveLineItemIsError,
    proveLineItemIsPending
} from "@redux/slices/prove/lineItem/selectors";
import {
    ExpandableBanner,
    ExpandableBannerBody,
    ExpandableBannerHeader
} from "@components/atoms/expandableBanner/ExpandableBanner";
import {
    Button,
    Container,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Input,
    Select,
    Spacer,
    Text,
    Tooltip,
    VStack
} from "@chakra-ui/react";
import {GlobalCampaignHeaderSection} from "@components/organisms";
import {DownloadIcon} from "@chakra-ui/icons";
import {ProveAdFormat} from "@api-clients/prove/schema/ProveAdFormat";
import {PlusIcon} from "@heroicons/react/24/solid";
import {ProveLineItemsTable} from "@components/organisms/prove/ProveLineItemsTable";
import {useForm} from "react-hook-form";
import {createProveLineItemAsync} from "@redux/slices/prove/lineItem/thunks";
import {usePosthogEvent} from "@hooks/_contexts/app/usePosthog";
import {useTranslation} from "react-i18next";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";
import moment from "moment";
import {routing} from "@configs";

export type SubTableDataType = ScenarioAdFormatResponseDto & { budget: number };
export type TableDataType = RawImportedDataRequestDto & {
    isChecked: boolean;
    adFormats?: SubTableDataType[];
};

interface FormData {
    name: string;
    mediaType: string;
    adFormat: string;
}

export const ProveManagementPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {campaignId} = useParams();
    const {getProveManagementServiceConfig, getProveTagServiceConfig} = useApiConfiguration();
    const selectedGlobalCampaignSelector = useSelector(selectedGlobalCampaign);
    const availableAdFormatsSelector = useSelector(proveAvailableAdFormats);
    const proveSelectedCampaignSelector = useSelector(proveSelectedCampaign);
    const globalCampaignIsPendingSelector = useSelector(globalCampaignIsPending);
    const proveCampaignIsPendingSelector = useSelector(proveCampaignIsPending);
    const proveCampaignIsErrorSelector = useSelector(proveCampaignIsError);
    const proveLineItemIsPendingSelector = useSelector(proveLineItemIsPending);
    const proveLineItemIsErrorSelector = useSelector(proveLineItemIsError);
    const availableLineItemsSelector = useSelector(proveAvailableLineItems);
    const adFormatIsPendingSelector = useSelector(proveAdFormatIsPending);
    const adFormatIsErrorSelector = useSelector(proveAdFormatIsError);
    const [supportedAdFormats, setSupportedAdFormats] = useState([] as Array<ProveAdFormat>);
    const {
        currentContextValues: {organisation}
    } = useAppContextHelper();
    const {
        helper: {
            selectGlobalCampaign,
            selectProveCampaign,
            retrieveProveAdFormats,
            retrieveProveLineItems,
            resolveProveAdFormatName
        },
    } = useAppContextHelper();

    const {t} = useTranslation('prove');
    const content = t('prove.lineItems', {
        returnObjects: true,
    });

    const containerRef = useRef<HTMLDivElement>(null);

    const emitPosthogCreateLineItemEvent = usePosthogEvent('Create new PROVE campaign line item');

    useEffect(() => {
        if (!campaignId) {
            return;
        }

        selectGlobalCampaign(campaignId).catch();
        selectProveCampaign(campaignId).catch();
        retrieveProveAdFormats().catch();
    }, []);

    const {
        handleSubmit,
        reset,
        register,
        formState: {errors, isValid},
    } = useForm<FormData>({
        mode: 'onChange'
    });

    useEffect(() => {
        if (!proveSelectedCampaignSelector) {
            return;
        }

        retrieveProveLineItems(proveSelectedCampaignSelector).catch();
    }, [proveSelectedCampaignSelector]);

    const isUniqueName = (value: string): boolean => {
        if (!availableLineItemsSelector) {
            return true;
        }

        for (let i = 0; i < availableLineItemsSelector.length; i++) {
            if (availableLineItemsSelector[i].name === value) {
                return false;
            }
        }

        return true;
    }

    const onFormSave = async (data: FormData) => {
        const managementConfiguration = await getProveManagementServiceConfig();
        const tagConfiguration = await getProveTagServiceConfig();

        dispatch(createProveLineItemAsync({
            managementConfiguration,
            tagConfiguration,
            campaign: proveSelectedCampaignSelector!,
            lineItem: {
                name: data.name,
                adFormat: data.adFormat
            } as ProveLineItem
        }));

        emitPosthogCreateLineItemEvent();

        reset();
    };

    const downloadTags = () => {
        let csvContent = "data:text/tsv;charset=utf-8,";

        csvContent += `${content.existing.csv.headers.name}\t${content.existing.csv.headers.adFormat}\t${content.existing.csv.headers.tag}\r\n`;

        const csvFormattedLineItems = availableLineItemsSelector!.filter((lineItem) => {
            return (lineItem.status === 'READY' && lineItem.tag);
        }).map((lineItem) => {
            return `${lineItem.name}\t${resolveProveAdFormatName(availableAdFormatsSelector!, lineItem.adFormat)}\t${lineItem.tag}\r\n`;
        });

        for (let i = 0; i < csvFormattedLineItems.length; i++) {
            csvContent += csvFormattedLineItems[i];
        }

        const encodedUri = encodeURI(csvContent);

        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${selectedGlobalCampaignSelector!.name} ${content.existing.csv.fileSuffix}.tsv`);
        document.body.appendChild(link);

        link.click();
    }

    function hasCampaignStarted() {
        const startDate = moment.utc(selectedGlobalCampaignSelector!.startDate);

        return startDate.isBefore(moment());
    }

    return !proveCampaignIsErrorSelector && !proveLineItemIsErrorSelector && !adFormatIsErrorSelector && organisation?.users ? (
        <>
            {!globalCampaignIsPendingSelector && !proveCampaignIsPendingSelector && proveSelectedCampaignSelector && selectedGlobalCampaignSelector && organisation.users ? (
                <>
                    <ExpandableBanner>
                        <ExpandableBannerHeader>
                            <Container ref={containerRef}>
                                <HStack>
                                    <GlobalCampaignHeaderSection
                                        campaign={selectedGlobalCampaignSelector}
                                        allowEdits={false}
                                    />
                                    <Spacer/>
                                    <Button
                                        rightIcon={<DownloadIcon/>}
                                        colorScheme="orange"
                                        onClick={() => {
                                            downloadTags();
                                        }}>
                                        {content.existing.buttons.downloadTags}
                                    </Button>
                                    <Tooltip isDisabled={hasCampaignStarted()}
                                             label="Dashboard is disabled until the campaign has started">
                                        <Button
                                            colorScheme="green"
                                            isDisabled={!hasCampaignStarted()}
                                            onClick={() => navigate(`/${routing.prove.root.path}/${selectedGlobalCampaignSelector.id}/${routing.prove.dashboard.path}`)}>
                                            {content.existing.buttons.viewDashboard}
                                        </Button>
                                    </Tooltip>
                                </HStack>
                            </Container>
                        </ExpandableBannerHeader>
                        <ExpandableBannerBody>
                            <Container>
                                <AsyncCard isLoading={proveLineItemIsPendingSelector || adFormatIsPendingSelector}>
                                    <Heading size="md" mb={2}>{content.new.title}</Heading>
                                    <Text mb={4}>{content.new.subtitle}</Text>
                                    <form onSubmit={handleSubmit(onFormSave)}>
                                        <Grid templateColumns="1fr 250px 250px 170px" width="100%" gap="1rem">
                                            <GridItem>
                                                <FormControl
                                                    isRequired
                                                    isInvalid={
                                                        errors.name !== undefined
                                                    }
                                                >
                                                    <FormLabel>{content.new.form.name.label}</FormLabel>
                                                    <Input
                                                        {...register('name', {
                                                            required: {
                                                                value: true,
                                                                message: content.new.form.name.requiredMessage
                                                            },
                                                            validate: (value) => (isUniqueName(value.trim())) ? true : content.new.form.name.duplicateMessage,
                                                            maxLength: {
                                                                value: 100,
                                                                message: content.new.form.name.exceedsMaxLengthMessage
                                                            }
                                                        })}
                                                    />
                                                    <FormErrorMessage>
                                                        {errors.name &&
                                                            (errors.name.message as string)}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem>
                                                <FormControl isRequired>
                                                    <FormLabel>
                                                        {content.new.form.mediaType.label}
                                                    </FormLabel>
                                                    <Select placeholder={content.new.form.mediaType.placeholder}
                                                            {...register('mediaType', {
                                                                required: true
                                                            })}
                                                            onChange={(event) => {
                                                                const selectedMediaType = event.target.value;

                                                                const adFormats = [] as Array<ProveAdFormat>;
                                                                for (let i = 0; i < availableAdFormatsSelector!.length; i++) {
                                                                    if (availableAdFormatsSelector![i].mediaType === selectedMediaType) {
                                                                        adFormats.push(availableAdFormatsSelector![i]);
                                                                    }
                                                                }

                                                                setSupportedAdFormats(adFormats);
                                                            }}>
                                                        <option
                                                            value='display'>{content.new.form.mediaType.display}</option>
                                                        <option
                                                            value='video'>{content.new.form.mediaType.video}</option>
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem>
                                                <FormControl isRequired>
                                                    <FormLabel>
                                                        {content.new.form.adFormat.label}
                                                    </FormLabel>
                                                    <Select
                                                        placeholder={content.new.form.adFormat.placeholder}
                                                        {...register(
                                                            'adFormat', {
                                                                required: true
                                                            }
                                                        )}
                                                    >
                                                        {supportedAdFormats.map(
                                                            (item) => {
                                                                return (
                                                                    <option
                                                                        key={item.code}
                                                                        value={
                                                                            item.code
                                                                        }
                                                                    >
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            },
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem>
                                                <VStack height="100%">
                                                    <Button
                                                        mt={6}
                                                        onClick={() => {
                                                            containerRef.current?.scrollIntoView(
                                                                {
                                                                    behavior: 'smooth',
                                                                    block: 'start',
                                                                },
                                                            );
                                                        }}
                                                        rightIcon={
                                                            <Icon as={PlusIcon}/>
                                                        }
                                                        isDisabled={!isValid}
                                                        type="submit"
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        {content.new.form.addButton}
                                                    </Button>
                                                </VStack>
                                            </GridItem>
                                        </Grid>
                                    </form>
                                </AsyncCard>
                            </Container>
                        </ExpandableBannerBody>
                    </ExpandableBanner>

                    <Container>
                        <AsyncCard isLoading={proveLineItemIsPendingSelector} my="1.5rem">
                            <Heading size="md" mb={2}>{content.existing.title}</Heading>
                            <Text mb={4}>{content.existing.subtitle}</Text>
                            {availableLineItemsSelector && availableAdFormatsSelector && (
                                <ProveLineItemsTable selectedCampaign={proveSelectedCampaignSelector}
                                                     availableAdFormats={availableAdFormatsSelector}
                                                     lineItems={availableLineItemsSelector}
                                                     users={organisation.users}/>
                            )}
                        </AsyncCard>
                    </Container>
                </>
            ) : (
                <Loading/>
            )}
        </>
    ) : (
        <ErrorCard/>
    );
};
