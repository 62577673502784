import {
    ApiService,
    CreateOauthApplicationRequestDto,
    CreateOauthApplicationResponseDto,
} from '@api-clients/account-manager';
import { FeatureCode } from '@api-clients/subscriptions';
import {
    Button,
    Checkbox,
    CheckboxGroup,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    Stack,
    useConst,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { InputWrapper } from '@components/atoms';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { PlusIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOauthApplications } from '@hooks/accountManager';
import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OAuthApplicationCreationPolicy } from '../OAuthApplicationCreationPolicy';

export interface OAuthApplicationFormData {
    applicationName: string;
    requestedApis: Array<ApiService>;
}

interface InternalProps {
    isDisabled?: boolean;
    onCreate: (response: CreateOauthApplicationResponseDto) => void;
}

export const CreateNewOAuthApplicationDialogButton: FC<InternalProps> = ({
    isDisabled,
    onCreate,
}) => {
    const { t } = useTranslation();
    const { errorToast, successToast } = useCustomToast();
    const { validationSchema, createOauthApplication } = useOauthApplications();
    const {
        currentContextValues: { organisation, user, featureBalances },
    } = useAppContextHelper();

    const hasAarcAccess = featureBalances?.some((f) => f.featureCode === FeatureCode.AarcApiAccess);
    const hasProveAccess = featureBalances?.some(
        (f) => f.featureCode === FeatureCode.ProveApiAccess,
    );
    const hasAttentionDataAccess = featureBalances?.some(
        (f) => f.featureCode === FeatureCode.AttentionDataApiAccess,
    );

    const defaultValues = [
        ...(hasAarcAccess ? [ApiService.ReachCurve] : []),
        ...(hasProveAccess ? [ApiService.AttentionProve] : []),
        ...(hasAttentionDataAccess ? [ApiService.AttentionData] : []),
    ];

    const emitStartCreatingApiApplicationEvent = usePosthogEvent('Start API application creation');
    const emitFinishCreatingApiApplicationEvent = usePosthogEvent(
        'Finish API application creation',
    );
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        watch,
        handleSubmit,
        register,
        reset,
        formState: { errors, touchedFields, isSubmitting, isValid },
    } = useForm<OAuthApplicationFormData>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: { requestedApis: defaultValues },
    });

    const translation = useConst(() =>
        t('pages.applicationManagement', {
            returnObjects: true,
        }),
    );

    const {
        createDialog: { headerText, confirmButton, cancelButton, form },
        toastText: {
            create: { success, error },
        },
    } = useConst(() => ({
        createDialog: translation.dialogs.create,
        toastText: translation.toast,
    }));

    const onFormSubmit = async (data: OAuthApplicationFormData) => {
        emitFinishCreatingApiApplicationEvent();
        try {
            const request: CreateOauthApplicationRequestDto = {
                applicationName: data.applicationName,
                organisationName: organisation?.organisationName,
                organisationRegion: organisation?.region,
                createdByUserId: user?.id,
                requestedApis: data.requestedApis,
            };

            const response = await createOauthApplication(organisation!.id!, request);
            onCreate(response);
            successToast(success.heading, success.subtitle);
        } catch (e) {
            errorToast(error.heading, error.subtitle);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const permissions = [
        {
            value: ApiService.AttentionData,
            label: 'Attention benchmarks',
            isDisabled: !hasAttentionDataAccess,
        },
        {
            value: ApiService.AttentionProve,
            label: 'Attention prove',
            isDisabled: !hasProveAccess,
        },
        {
            value: ApiService.ReachCurve,
            label: ' Attention-adjusted® reach curves',
            isDisabled: !hasAarcAccess,
        },
    ];

    return (
        <>
            <Button
                leftIcon={<Icon as={PlusIcon} />}
                isDisabled={isDisabled}
                onClick={() => {
                    onOpen();
                    emitStartCreatingApiApplicationEvent();
                }}
            >
                {translation.buttons.create}
            </Button>
            <ConfirmDialog
                size="xl"
                headerText={headerText}
                confirmButtonText={confirmButton}
                cancelButtonText={cancelButton}
                isOpen={isOpen}
                isLoading={isSubmitting}
                isConfirmDisabled={!isValid}
                onClose={(isConfirmation) => {
                    if (isConfirmation) {
                        handleSubmit(onFormSubmit)();
                    } else {
                        onClose();
                    }
                }}
            >
                <VStack spacing="1rem" align="left">
                    <OAuthApplicationCreationPolicy />
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <VStack align="left">
                            <InputWrapper
                                isRequired
                                formLabel={form.fields.applicationName}
                                formErrorText={errors.applicationName?.message}
                                isInvalid={
                                    touchedFields.applicationName && !!errors.applicationName
                                }
                            >
                                <Input {...register('applicationName')} />
                            </InputWrapper>
                            <Divider />
                            <FormControl isInvalid={!!errors.requestedApis} isRequired>
                                <FormLabel>Permissions</FormLabel>
                                <CheckboxGroup defaultValue={defaultValues}>
                                    <Stack>
                                        {permissions.map((p) => (
                                            <Checkbox
                                                {...register('requestedApis')}
                                                value={p.value}
                                                isDisabled={p.isDisabled}
                                            >
                                                {p.label}
                                            </Checkbox>
                                        ))}
                                        <FormErrorMessage>
                                            Must select at least one permission.
                                        </FormErrorMessage>
                                    </Stack>
                                </CheckboxGroup>
                            </FormControl>
                        </VStack>
                    </form>
                </VStack>
            </ConfirmDialog>
        </>
    );
};
