import {
    Badge,
    Button,
    Center,
    Flex,
    Heading,
    Icon,
    Indicator,
    Link as ChakraLink,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { importTemplate, routing } from '@configs';
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid';
import { AmplifiedColorScheme } from '@shared/cores';
import { FC } from 'react';
import { useAppContextHelper } from '@hooks/_contexts';
import posthog from 'posthog-js';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export interface ImportTabBannerProps {
    colorScheme?: AmplifiedColorScheme;
}

export const ImportTabBanner: FC<ImportTabBannerProps> = ({ colorScheme = 'green' }) => {
    const { fileUrl } = importTemplate;
    const {
        currentContextValues: { organisation, user },
    } = useAppContextHelper();

    function sendEventToPostHog() {
        posthog.capture('Download template (2/5)', {
            organisation_id: organisation!.id!,
            organisation_name: organisation!.organisationName!,
            user_id: user!.id!,
            user_name: `${user!.firstName!} ${user!.lastName!}`,
            timestamp: Date.now().toLocaleString(),
        });
    }

    return (
        <Flex paddingX="2.5rem" paddingY="1.5rem" bgColor={`${colorScheme}.50`}>
            <VStack align="left">
                <Heading
                    color="gray.800"
                    size="sm"
                    fontSize="1.125rem"
                    fontWeight={700}
                    lineHeight="1.75rem"
                >
                    Download our template and upload it here
                </Heading>
                <Flex alignItems="center" gap="0.375rem">
                    <Icon as={ExclamationCircleIcon} color="yellow.500" strokeWidth="2px" />
                    <Text
                        color="gray.600"
                        fontSize="0.875rem"
                        fontWeight={400}
                        lineHeight="1.25rem"
                    >
                        Importing data from a file will override your current media plan. Please
                        review carefully before clicking confirm.
                    </Text>
                </Flex>
            </VStack>
            <Spacer />
            <Center>
                <VStack>
                    <Button
                        onClick={() => sendEventToPostHog()}
                        variant="outline"
                        colorScheme={colorScheme}
                        leftIcon={<Icon as={DocumentArrowDownIcon} />}
                        as={ChakraLink}
                        download
                        href={fileUrl}
                        isExternal={false}
                    >
                        Download Template
                    </Button>
                </VStack>
            </Center>
        </Flex>
    );
};
