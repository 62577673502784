export const freemium = {
    features: {
        '0': "Gain insights into channels and formats' performance with attention benchmarks",
        '1': 'Create unlimited optimised media plan scenarios',
        '2': 'Access specific local market data from 16 countries',
        '3': 'Tailor your media plan towards age group, gender and campaign objectives',
        '4': 'Iterate media plan performance across 9 platforms and 40 formats',
        '5': 'Utilise market leading Attention-adjusted® reach curve functionality',
        '6': 'And more!',
    },
    plans: {
        payg: {
            label: 'PAYG',
            pricing: '$1000 PAYG',
            subscriptionLabel: '',
        },
        standard: {
            label: 'Standard',
            pricing: '$1000/month',
            subscriptionLabel: '12 month subscription',
        },
        professional: {
            label: 'Professional',
            pricing: '$5000/month',
            subscriptionLabel: '3 month subscription',
        },
        advanced: {
            label: 'Advanced',
            pricing: '$4000/month',
            subscriptionLabel: '12 month subscription',
        },
    },
} as const;
