import {createSlice} from '@reduxjs/toolkit'
import {
    createProveLineItemAsync,
    findProveLineItemsAsync,
    generateProveLineItemJsTagAsync,
    generateProveLineItemVastLinearWrapperTagAsync,
    saveProveLineItemVastAsync
} from "@/redux/slices/prove/lineItem/thunks";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";

export interface LineItemState {
    available: Array<ProveLineItem> | undefined
    isPending: boolean
    isError: boolean
}

const initialState: LineItemState = {
    available: undefined,
    isPending: false,
    isError: false
}

const mergeLineItem = (target: ProveLineItem, available: Array<ProveLineItem>) => {
    const availableLineItems = [] as Array<ProveLineItem>;

    for (let i = 0; i < available.length; i++) {
        const lineItem = available[i];

        availableLineItems.push((lineItem.id === target.id) ? target : lineItem);
    }

    return availableLineItems;
}

export const lineItemSlice = createSlice({
    name: 'prove/lineItem',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findProveLineItemsAsync.pending, (state, action) => {
                state.isPending = true;
                state.available = [];
            })
            .addCase(findProveLineItemsAsync.rejected, (state, action) => {
                state.isError = true
                state.isPending = false;
            })
            .addCase(findProveLineItemsAsync.fulfilled, (state, action) => {
                state.available = action.payload || [];
                state.isPending = false;
            })
            .addCase(createProveLineItemAsync.pending, (state) => {
                state.isPending = true;
            })
            .addCase(createProveLineItemAsync.rejected, (state, action) => {
                state.isError = true
                state.isPending = false;
            })
            .addCase(createProveLineItemAsync.fulfilled, (state, action) => {
                state.available = state.available || [];
                state.available.push(action.payload);
                state.isPending = false;
            })
            .addCase(saveProveLineItemVastAsync.pending, (state) => {
                state.isPending = true;
            })
            .addCase(saveProveLineItemVastAsync.rejected, (state, action) => {
                state.isError = true
                state.isPending = false;
            })
            .addCase(saveProveLineItemVastAsync.fulfilled, (state, action) => {
                state.available = mergeLineItem({...action.payload}, state.available!);
                state.isPending = false;
            })
            .addCase(generateProveLineItemJsTagAsync.pending, (state) => {
                state.isPending = true;
            })
            .addCase(generateProveLineItemJsTagAsync.rejected, (state, action) => {
                state.isError = true
                state.isPending = false;
            })
            .addCase(generateProveLineItemJsTagAsync.fulfilled, (state, action) => {
                state.available = mergeLineItem({...action.payload}, state.available!);
                state.isPending = false;
            })
            .addCase(generateProveLineItemVastLinearWrapperTagAsync.pending, (state) => {
                state.isPending = true;
            })
            .addCase(generateProveLineItemVastLinearWrapperTagAsync.rejected, (state, action) => {
                state.isError = true
                state.isPending = false;
            })
            .addCase(generateProveLineItemVastLinearWrapperTagAsync.fulfilled, (state, action) => {
                state.available = mergeLineItem({...action.payload}, state.available!);
                state.isPending = false;
            })
    },
})
