import { ImportTabBanner } from '@apps/attentionADJUST/components/molecules';
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { FileUploadContainer } from '@components/atoms';
import { mimeType } from '@components/atoms/fileUploadContainer/FileUploadContainer';
import { FC } from 'react';

type ImportFileModalProps = {
    onConfirm:  () => void;
    isOpen: boolean
    onClose: () => void;
    acceptedFileTypes: mimeType[];
    onFileUpload: (file: File[]) => void
};
export const ImportFileModal: FC<ImportFileModalProps> = ({ onConfirm, onClose, isOpen, acceptedFileTypes, onFileUpload}) => {
    return <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Import from file</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
                <ImportTabBanner />
                <Box p="3rem">
                    <Box maxWidth="48rem" mx="auto">
                        <FileUploadContainer
                            isMediaImportTemplate
                            maxFiles={1}
                            acceptedFileTypes={acceptedFileTypes}
                            onFileUpload={onFileUpload}
                        />
                    </Box>
                </Box>
            </ModalBody>
            <ModalFooter display="flex" alignItems="center" justifyContent="flex-end" gap="1rem">
                <Button variant="ghost" onClick={onClose}>
                    Close
                </Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                >
                    Confirm
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>;
};
