import {ContainerTitle,} from '@apps/attentionADJUST/components/atoms';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    CloseButton,
    Container,
    Flex,
    Spacer,
    VStack
} from '@chakra-ui/react';
import {Loading} from '@components/atoms';
import {ErrorCard} from '@components/molecules/errorCard/ErrorCard';
import {routing} from '@configs';
import {useAppContextHelper} from '@hooks/_contexts';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {usePosthogEvent} from '@hooks/_contexts/app/usePosthog';
import {useDispatch, useSelector} from "@redux";
import {
    availableGlobalCampaigns,
    globalCampaignIsError,
    globalCampaignIsPending
} from "@redux/slices/global/campaign/selectors";
import {findGlobalCampaignsAsync} from "@redux/slices/global/campaign/thunks";
import {useApiConfiguration} from "@hooks/configuration";
import {GlobalCampaignsTable} from "@components/global/GlobalCampaignsTable";
import {CampaignManagementSection} from "@components/organisms/campaignManagementSection/CampaignManagementSection";
import {useGetCampaigns} from "@hooks/campaigns";
import {CampaignBasicInfoResponseDto} from "@api-clients/media-plan";
import {useLocalStorage} from "@hooks/localStorage";
import {CacheKey} from "@shared/cores";
import {ProveAnnouncementModal} from "@components/molecules/modals/proveAnnouncementModal/ProveAnnouncementModal";
import {resetSelectedGlobalCampaign} from "@redux/slices/global/campaign";
import {resetSelectedProveCampaign} from "@redux/slices/prove/campaign";

export const GlobalCampaignsPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {getCampaigns} = useGetCampaigns();
    const {getGlobalCampaignServerConfig} = useApiConfiguration();
    const {currentContextValues: {organisation}} = useAppContextHelper();
    const {t: globalCampaignTranslation} = useTranslation('campaign');
    const {t: mediaPlanningTranslation} = useTranslation('mediaPlans');
    const [hasLoadedMediaPlans, setHasLoadedMediaPlans] = useState<boolean>(false);
    const [legacyMediaPlans, setLegacyMediaPlans] = useState<CampaignBasicInfoResponseDto[]>([]);
    const [globalCampaignMediaPlans, setGlobalCampaignMediaPlans] = useState<CampaignBasicInfoResponseDto[]>([]);
    const [dismissedUserPrompts, setDismissedUserPrompts] = useLocalStorage(
        CacheKey.DismissedUserPrompts, {
            legacyMediaPlans: false,
            proveAnnouncement: false
        }
    );

    const campaignContent = globalCampaignTranslation('campaign.main', {
        returnObjects: true,
    });

    const mediaPlanContent = mediaPlanningTranslation('mediaPlanning.campaignManagement', {
        returnObjects: true,
    });

    const availableGlobalCampaignsSelector = useSelector(availableGlobalCampaigns);
    const globalCampaignIsPendingSelector = useSelector(globalCampaignIsPending);
    const globalCampaignIsErrorSelector = useSelector(globalCampaignIsError);

    useEffect(() => {
        dispatch(resetSelectedGlobalCampaign());
        dispatch(resetSelectedProveCampaign());

        const fetchGlobalCampaigns = async () => {
            dispatch(findGlobalCampaignsAsync({
                configuration: await getGlobalCampaignServerConfig()
            }));
        }

        fetchGlobalCampaigns().catch();
    }, []);

    useEffect(() => {
        getCampaigns().then((mediaPlans) => {
            if (mediaPlans && mediaPlans.length > 0) {
                setLegacyMediaPlans(mediaPlans.filter((entry) => {
                    return entry.campaignId === undefined;
                }));

                setGlobalCampaignMediaPlans(mediaPlans.filter((entry) => {
                    return entry.campaignId !== undefined;
                }));
            }

            setHasLoadedMediaPlans(true);
        });
    }, []);

    const dismissLegacyMediaPlansPrompt = () => {
        setDismissedUserPrompts({
            ...dismissedUserPrompts,
            legacyMediaPlans: true,
        });
    }

    const emitPosthogEvent = usePosthogEvent('Creating new global campaign');

    const handleProveAnnouncementModalClose = () => {
        setDismissedUserPrompts({
            ...dismissedUserPrompts,
            proveAnnouncement: true,
        });
    }

    return (
        <>
            {dismissedUserPrompts && (
                <ProveAnnouncementModal
                    key="ProveAnnouncementModal_GlobalCampaignPage"
                    isOpen={!dismissedUserPrompts.proveAnnouncement}
                    onClose={handleProveAnnouncementModalClose}
                />
            )}
            {!globalCampaignIsErrorSelector && organisation?.users ? (
                <>
                    {!globalCampaignIsPendingSelector && availableGlobalCampaignsSelector && hasLoadedMediaPlans ? (
                        <Container>
                            <VStack height="100%" align="left" spacing="1rem">
                                <Flex>
                                    <ContainerTitle
                                        headingText={campaignContent.heading}
                                        subtitleText={campaignContent.subtitle}
                                        size="xl"
                                    />
                                    <Spacer/>
                                    <Button
                                        id="create-prove-campaign-btn-id"
                                        colorScheme="orange"
                                        onClick={() => {
                                            navigate(`/${routing.campaign.root.path}/${routing.campaign.create.path}`);
                                            emitPosthogEvent();
                                        }}
                                    >
                                        {campaignContent.createButton}
                                    </Button>
                                </Flex>
                                <GlobalCampaignsTable
                                    users={organisation.users}
                                    campaigns={availableGlobalCampaignsSelector}
                                    mediaPlans={globalCampaignMediaPlans}
                                />
                                {legacyMediaPlans && legacyMediaPlans.length > 0 && (
                                    <>
                                        <ContainerTitle
                                            headingText={mediaPlanContent.heading}
                                            subtitleText={mediaPlanContent.subtitle}
                                            size="xl"
                                        />
                                        {dismissedUserPrompts && !dismissedUserPrompts.legacyMediaPlans && (
                                            <Alert status='info'
                                                   variant='left-accent'
                                                   rounded="0.3rem"
                                                   alignItems="left">
                                                <AlertIcon/>
                                                <VStack
                                                    alignItems="left">
                                                    <AlertTitle>New UI update - All existing attentionPLAN media
                                                        plans can be found here</AlertTitle>
                                                    <AlertDescription>We've just released a new platform update to
                                                        enhance your media planning and tracking experience. Worry
                                                        not - all media plans that you have created via
                                                        attentionPLAN can be found here while we're working to move
                                                        them into the campaign section above.</AlertDescription>
                                                </VStack>
                                                <CloseButton
                                                    alignSelf='flex-start'
                                                    position='relative'
                                                    right={-1}
                                                    top={-1}
                                                    onClick={dismissLegacyMediaPlansPrompt}
                                                />
                                            </Alert>
                                        )}
                                        <CampaignManagementSection legacyMediaPlans={legacyMediaPlans!}/>
                                    </>
                                )}
                            </VStack>
                        </Container>
                    ) : (
                        <Loading/>
                    )}
                </>

            ) : (
                <ErrorCard/>
            )}
        </>
    );
};
