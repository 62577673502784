import { FormatResponseDto, GetAttentionDataResponseDto } from '@api-clients/attention-data';
import { AgeGroup, Gender, ScenarioAdFormatResponseDto } from '@api-clients/media-plan';
import { CountryCodeIso2 } from '@api-clients/shared';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { AttentionMetricsChart } from '@apps/attentionADJUST/components/molecules/chart';
import { AttentionMetricsPdf } from '@apps/attentionADJUST/components/organisms';
import { attentionPlanExcelLogo } from '@assets/images';
import { ChevronDownIcon, LockIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Card,
    CardBody,
    Center,
    Container,
    Flex,
    FormLabel,
    Heading,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select,
    Skeleton,
    Spacer,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { Column, DataTable, Row } from '@components/molecules';
import { AdFormatsBasicModal } from '@components/molecules/modals/adFormatsBasicModal/AdFormatsBasicModal';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
    AdjustmentsHorizontalIcon,
    ArrowDownIcon,
    ArrowsUpDownIcon,
    ArrowUpIcon,
    ArrowUpTrayIcon,
} from '@heroicons/react/24/outline';
import { pdf } from '@react-pdf/renderer';
import { SortDirection } from '@shared/cores';
import { findCountryName } from '@shared/cores/types/Country';
import { arrayBufferToBase64, downloadBlob, toLocaleFixed, useHelper } from '@shared/utils';
import { Chart } from 'chart.js';
import ExcelJS from 'exceljs';
import { FC, useMemo, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useTranslation } from 'react-i18next';

type ChartSortOptions =
    | 'none'
    | 'averageActiveAttentionSeconds'
    | 'averagePassiveAttentionSeconds'
    | 'totalAttention';

interface AttentionMetricSectionProps {
    isPaidPlan: boolean;
    attentionData?: Array<GetAttentionDataResponseDto>;
    channels?: Array<FormatResponseDto>;
    handleFormatConfirm: (channelData: ScenarioAdFormatResponseDto[]) => void;
    adFormats: ScenarioAdFormatResponseDto[];
    isFetching: boolean;
    selectedCountries: CountryCodeIso2[];
    selectedAgeGroups: AgeGroup[];
    selectedGenders: Gender[];
    isUsingGlobal: boolean;
    isMrcCompliantOnly: boolean;
}

interface DataRow {
    platform: string;
    format: string;
    activeAttention?: string;
    passiveAttention?: string;
    totalAttention?: string;
    averageAdLength?: string;
    averageAdTimeOnScreen?: string;
}

export const AttentionMetricSection: FC<AttentionMetricSectionProps> = ({
    isPaidPlan,
    attentionData,
    channels,
    handleFormatConfirm,
    adFormats,
    isFetching,
    selectedCountries,
    selectedAgeGroups,
    selectedGenders,
    isUsingGlobal,
    isMrcCompliantOnly,
}) => {
    const chartChunkCount = 15;
    const { t } = useTranslation('benchmark');
    const attentionDataText = t('attentionBenchmarks.metrics', {
        returnObjects: true,
    });
    const lockTooltip = t('attentionBenchmarks.lockTooltip', {
        returnObjects: true,
    });

    const [chartSortBy, setChartSortBy] = useState<ChartSortOptions>('none');
    const [chartSortDirection, setChartSortDirection] = useState<SortDirection>(1);
    const chartRef = useRef<ChartJSOrUndefined<'bar', string[], object>>(null);

    const {
        isOpen: isAdFormatModalOpen,
        onOpen: onAdFormatModalOpen,
        onClose: onAdFormatModalClose,
    } = useDisclosure();

    const chartSortOptions: { label: string; value: ChartSortOptions }[] = [
        { label: 'None', value: 'none' },
        { label: 'Active attention seconds', value: 'averageActiveAttentionSeconds' },
        { label: 'Passive attention seconds', value: 'averagePassiveAttentionSeconds' },
        { label: 'Total attention seconds', value: 'totalAttention' },
    ];

    const columns: Array<Column<DataRow>> = [
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            sorter: (a, b) => a.platform.localeCompare(b.platform),
        },
        {
            title: 'Format',
            dataIndex: 'format',
            key: 'format',
            sorter: (a, b) => a.format.localeCompare(b.format),
        },
        {
            title: <>Active attention&nbsp;(s)</>,
            dataIndex: 'activeAttention',
            key: 'activeAttention',
            textAlign: 'right',
            isNumeric: true,
            tooltip: attentionDataText.tableTooltip.activeAttention,
            sorter: (a, b) => Number(a.activeAttention) - Number(b.activeAttention),
            render: (row, index) => {
                return index >= 1 && !isPaidPlan ? (
                    <Tooltip label={lockTooltip}>
                        <Box width="100%">‎</Box>
                    </Tooltip>
                ) : (
                    <>{row.activeAttention}</>
                );
            },
            onCell: (row, index) =>
                index >= 1 && !isPaidPlan ? { backgroundColor: 'gray.50' } : {},
        },

        {
            title: <>Passive attention&nbsp;(s)</>,
            dataIndex: 'passiveAttention',
            key: 'passiveAttention',
            textAlign: 'right',
            isNumeric: true,
            tooltip: attentionDataText.tableTooltip.passiveAttention,
            sorter: (a, b) => Number(a.passiveAttention) - Number(b.passiveAttention),
            render: (row, index) => {
                return index >= 1 && !isPaidPlan ? (
                    <Tooltip label={lockTooltip}>
                        <Box width="100%">‎</Box>
                    </Tooltip>
                ) : (
                    <>{row.passiveAttention}</>
                );
            },
            onCell: (row, index) =>
                index >= 1 && !isPaidPlan ? { backgroundColor: 'gray.50' } : {},
        },
        {
            title: <>Total attention&nbsp;(s)</>,
            dataIndex: 'totalAttention',
            key: 'totalAttention',
            textAlign: 'right',
            isNumeric: true,
            tooltip: attentionDataText.tableTooltip.totalAttention,
            sorter: (a, b) => Number(a.totalAttention) - Number(b.totalAttention),
            render: (row, index) => {
                return index >= 1 && !isPaidPlan ? (
                    <Tooltip label={lockTooltip}>
                        <Box width="100%">‎</Box>
                    </Tooltip>
                ) : (
                    <>{row.totalAttention}</>
                );
            },
            onCell: (row, index) =>
                index >= 1 && !isPaidPlan ? { backgroundColor: 'gray.50' } : {},
        },
        // {
        //     title: <>Active attention % to</>,
        //     key: 'activeAttentionTo',
        //     textAlign: 'center',
        //     subColumns: [
        //         {
        //             title: <>ad length</>,
        //             dataIndex: 'averageAdLength',
        //             key: 'averageAdLength',
        //             textAlign: 'right',
        //             isNumeric: true,
        //             tooltip: attentionDataText.tableTooltip.averageAdLength,
        //             sorter: (a, b) => {
        //                 const averageadLengthA =
        //                     (Number.parseFloat(a.activeAttention ?? '0') /
        //                         (Number.parseFloat(a.averageAdLength ?? '0') ?? 0)) *
        //                     100;
        //                 const averageadLengthB =
        //                     (Number.parseFloat(b.activeAttention ?? '0') /
        //                         (Number.parseFloat(b.averageAdLength ?? '0') ?? 0)) *
        //                     100;
        //                 return averageadLengthA - averageadLengthB;
        //             },
        //             render: (row, index) => {
        //                 const averageadLength =
        //                     (Number.parseFloat(row.activeAttention ?? '0') /
        //                         (Number.parseFloat(row.averageAdLength ?? '0') ?? 0)) *
        //                     100;
        //
        //                 return index >= 1 && !isPaidPlan ? (
        //                     <Tooltip label={lockTooltip}>
        //                         <Box width="100%">‎</Box>
        //                     </Tooltip>
        //                 ) : (
        //                     <>
        //                         {row.averageAdLength && Number.parseFloat(row.averageAdLength) > 0
        //                             ? `${toLocaleFixed(averageadLength, 1)}%`
        //                             : 'N/A'}
        //                     </>
        //                 );
        //             },
        //             onCell: (row, index) =>
        //                 index >= 1 && !isPaidPlan ? { backgroundColor: 'gray.50' } : {},
        //         },
        //         {
        //             title: <>ad time on screen</>,
        //             dataIndex: 'averageAdTimeOnScreen',
        //             key: 'averageAdTimeOnScreen',
        //             textAlign: 'right',
        //             isNumeric: true,
        //             tooltip: attentionDataText.tableTooltip.averageAdTimeOnScreen,
        //             sorter: (a, b) => {
        //                 const averageAdTimeOnScreenA =
        //                     (Number.parseFloat(a.activeAttention ?? '0') /
        //                         (Number.parseFloat(a.averageAdTimeOnScreen ?? '0') ?? 0)) *
        //                     100;
        //                 const averageAdTimeOnScreenB =
        //                     (Number.parseFloat(b.activeAttention ?? '0') /
        //                         (Number.parseFloat(b.averageAdTimeOnScreen ?? '0') ?? 0)) *
        //                     100;
        //                 return averageAdTimeOnScreenA - averageAdTimeOnScreenB;
        //             },
        //             render: (row, index) => {
        //                 const averageadTimeOnScreen =
        //                     (Number.parseFloat(row.activeAttention ?? '0') /
        //                         (Number.parseFloat(row.averageAdTimeOnScreen ?? '0') ?? 0)) *
        //                     100;
        //                 return index >= 1 && !isPaidPlan ? (
        //                     <Tooltip label={lockTooltip}>
        //                         <Box width="100%">‎</Box>
        //                     </Tooltip>
        //                 ) : (
        //                     <>
        //                         {row.averageAdTimeOnScreen &&
        //                         Number.parseFloat(row.averageAdTimeOnScreen) > 0
        //                             ? `${toLocaleFixed(averageadTimeOnScreen, 1)}%`
        //                             : 'N/A'}
        //                     </>
        //                 );
        //             },
        //             onCell: (row, index) =>
        //                 index >= 1 && !isPaidPlan ? { backgroundColor: 'gray.50' } : {},
        //         },
        //     ],
        // },
    ];

    const dataSource = useMemo(
        () =>
            attentionData
                ?.sort((a, b) => a.adFormat?.code?.localeCompare(b.adFormat?.code ?? '') ?? 0)
                .map(
                    (s) =>
                        ({
                            key: `${s.adChannel!.name}_${s.adFormat!.name}`,
                            platform: s.adChannel!.name,
                            format: s.adFormat!.name,
                            activeAttention: toLocaleFixed(s.averageActiveAttentionSeconds ?? 0, 1),
                            passiveAttention: toLocaleFixed(
                                s.averagePassiveAttentionSeconds ?? 0,
                                1,
                            ),
                            totalAttention: toLocaleFixed(
                                (s.averageActiveAttentionSeconds ?? 0) +
                                    (s.averagePassiveAttentionSeconds ?? 0),
                                1,
                            ),
                            averageAdLength: toLocaleFixed(s.averageAdLengthSeconds ?? 0, 1),
                            averageAdTimeOnScreen: toLocaleFixed(
                                s.averageAdTimeOnScreenSeconds ?? 0,
                                1,
                            ),
                        } as Row<DataRow>),
                ),
        [attentionData],
    );

    const handleAdFormatConfirm = (data: ScenarioAdFormatResponseDto[]) => {
        onAdFormatModalClose();
        handleFormatConfirm(data);
    };

    const handleChartSort = (value: ChartSortOptions) => {
        setChartSortDirection(-1);
        setChartSortBy(value);
    };

    const renderNoAttentionDataFound = (): ReactJSXElement => (
        <VStack>
            <Heading variant="amplifiedHeading">No attention data found</Heading>
            <Text fontSize="sm">Reload the page or change the filters</Text>
        </VStack>
    );

    const { formatAgeGroup, formatNumber, formatStringToCapitalized } = useHelper();

    const handleExportToExcel = async () => {
        const solidBorder: Partial<ExcelJS.Borders> = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        };
        const headerCellStyle: Partial<ExcelJS.Style> = {
            font: { color: { argb: 'ffffffff' }, size: 14, bold: true, name: 'Calibri' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '#ff2e6e45' },
            },
            alignment: { vertical: 'middle', horizontal: 'center' },
        };
        const tableHeaderCellStyle: Partial<ExcelJS.Style> = {
            font: { size: 14, bold: true, name: 'Calibri' },
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: solidBorder,
        };
        const tableValueCellStyle: Partial<ExcelJS.Style> = {
            font: { size: 14, name: 'Calibri' },
            border: solidBorder,
            numFmt: '#,##0.0',
        };

        const shortCOLS = 'AB';
        const COLS = 'ABCDE';

        const workbook = new ExcelJS.Workbook();
        // Set metadata info
        workbook.creator = 'Amplified Intelligence';
        workbook.lastModifiedBy = 'Amplified Intelligence';
        const now = new Date();
        workbook.created = now;
        workbook.modified = now;
        workbook.calcProperties.fullCalcOnLoad = true;
        const sheet = workbook.addWorksheet('data');
        sheet.properties.defaultColWidth = 45;

        // add logo
        const firstRow = sheet.getRow(1);
        firstRow.height = 60;
        const res = await fetch(attentionPlanExcelLogo);
        const imageBuffer = await res.arrayBuffer();
        const logoId = workbook.addImage({ buffer: imageBuffer, extension: 'png' });
        sheet.addImage(logoId, 'A1:A1');

        const timestampCell = sheet.getCell('A3');
        timestampCell.style = {
            font: { size: 14, name: 'Calibri' },
        };
        timestampCell.value = `Exported at: ${new Date().toLocaleString()}`;

        // Filter information
        const benchmarkFiltersTitleCell = sheet.getCell('A5');
        benchmarkFiltersTitleCell.value = 'ATTENTION BENCHMARK FILTERS';
        benchmarkFiltersTitleCell.style = headerCellStyle;
        // always starts at A7 and B7
        const filterHeaderCell = sheet.getCell('A7');
        filterHeaderCell.value = 'Filters';
        const valuesHeaderCell = sheet.getCell('B7');
        valuesHeaderCell.value = 'Values';
        filterHeaderCell.style = tableHeaderCellStyle;
        valuesHeaderCell.style = tableHeaderCellStyle;
        sheet.getCell('A8').value = 'Country';
        sheet.getCell('B8').value = isUsingGlobal
            ? 'Global'
            : selectedCountries.map((c) => findCountryName(c)).join(', ');
        sheet.getCell('A9').value = 'Age group';
        sheet.getCell('B9').value = selectedAgeGroups.map((ag) => formatAgeGroup(ag)).join(', ');
        sheet.getCell('A10').value = 'Gender';
        sheet.getCell('B10').value = selectedGenders
            .map((gender) => formatStringToCapitalized(gender))
            .join(', ');
        sheet.getCell('A11').value = 'MRC compilant only';
        sheet.getCell('B11').value = isMrcCompliantOnly ? 'Yes' : 'No';
        // apply styles
        for (let row = 8; row <= 11; row++) {
            for (let j = 0; j < shortCOLS.length; j++) {
                const col = shortCOLS[j];
                const cell = sheet.getCell(`${col}${row}`);
                cell.style = tableValueCellStyle;
            }
        }

        const attentionMetricsTitleCell = sheet.getCell('A14');
        attentionMetricsTitleCell.value = 'ATTENTION METRICS - METRIC VIEW';
        attentionMetricsTitleCell.style = headerCellStyle;

        sheet.getCell('A16').value = 'Platform';
        sheet.getCell('B16').value = 'Format';
        sheet.getCell('C16').value = 'Active attention(s)';
        sheet.getCell('D16').value = 'Passive attention(s)';
        sheet.getCell('E16').value = 'Total attention(s)';
        for (let j = 0; j < COLS.length; j++) {
            const col = COLS[j];
            sheet.getCell(`${col}16`).style = tableHeaderCellStyle;
        }

        let rowNumber = 17;
        // write attention data
        if (attentionData && attentionData.length) {
            if (!isPaidPlan) attentionData = attentionData?.filter((_, index) => index === 0);
            for (let i = 0; i < attentionData.length; i++, rowNumber++) {
                const data = attentionData[i];
                const platform = data?.adChannel?.name ?? '';
                const format = data.adFormat?.name ?? '';
                const activeAttention = data.averageActiveAttentionSeconds;
                const passiveAttention = data.averagePassiveAttentionSeconds;
                const totalAttention = (activeAttention ?? 0) + (passiveAttention ?? 0);
                sheet.getCell(`A${rowNumber}`).value = platform;
                sheet.getCell(`B${rowNumber}`).value = format;
                sheet.getCell(`C${rowNumber}`).value = activeAttention;
                sheet.getCell(`D${rowNumber}`).value = passiveAttention;
                sheet.getCell(`E${rowNumber}`).value = totalAttention;
                for (let j = 0; j < COLS.length; j++) {
                    const col = COLS[j];
                    sheet.getCell(`${col}${rowNumber}`).style = tableValueCellStyle;
                }
            }
        }

        rowNumber += 2;
        // adding a title cell for metrics
        const chartTitleCell = sheet.getCell(`A${rowNumber}`);
        chartTitleCell.value = 'ATTENTION METRICS - CHART VIEW';
        chartTitleCell.style = headerCellStyle;

        rowNumber += 1;
        // add the picture
        const chartImageDataURL = chartRef.current?.toBase64Image() ?? '';
        const chartImageDimension = await new Promise<{ width: number; height: number }>(
            (resolve, reject) => {
                const chartImage = new Image();
                chartImage.src = chartImageDataURL;
                chartImage.onload = () => {
                    resolve({ width: chartImage.naturalWidth, height: chartImage.naturalHeight });
                };
            },
        );
        const chartImageId = workbook.addImage({ base64: chartImageDataURL, extension: 'png' });
        const imageScaleFactor = 0.5;
        sheet.addImage(chartImageId, {
            tl: { row: rowNumber, col: 0 },
            ext: {
                width: chartImageDimension.width * imageScaleFactor,
                height: chartImageDimension.height * imageScaleFactor,
            },
        });

        const buf = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buf], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'",
        });
        const day = now.getDate();
        const month = now.getMonth() + 1;
        const year = now.getFullYear();
        downloadBlob(blob, `attentionPLAN-Attention_benchmarks_${day}_${month}_${year}.xlsx`);
    };

    const chunkArray = (array: string | any[], chunkSize: number) => {
        const resultArray = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            resultArray.push(array.slice(i, i + chunkSize));
        }
        return resultArray;
    };

    const chunkedAttentionData = useMemo(() => {
        return chunkArray(attentionData ?? [], chartChunkCount);
    }, [attentionData]);

    const chartIdPrefix = 'AttentionMetricsChartHidden_';
    const getChartImages = async (): Promise<string[]> => {
        const chartCount = isPaidPlan ? chunkedAttentionData.length : 1;
        let images: string[] = [];
        for (let i = 0; i < chartCount; i++) {
            const chart = Chart.getChart(`${chartIdPrefix}${i}`);
            const image = chart?.toBase64Image('image/png', 1);
            if (image) images.push(image);
        }
        return images;
    };

    const handleExportToPdf = async () => {
        const images = await getChartImages();
        const blob = await pdf(
            <AttentionMetricsPdf
                attentionData={
                    (isPaidPlan
                        ? attentionData
                        : attentionData?.filter((_, index) => index === 0)) ?? []
                }
                isUsingGlobal={isUsingGlobal}
                countries={selectedCountries}
                ageGroups={selectedAgeGroups}
                genders={selectedGenders}
                isMrcCompliantOnly={isMrcCompliantOnly}
                charts={images}
                isPaidPlan={isPaidPlan}
            />,
        ).toBlob();
        const now = new Date();
        const day = now.getDate();
        const month = now.getMonth() + 1;
        const year = now.getFullYear();
        downloadBlob(blob, `attentionPLAN-Attention_benchmarks_${day}_${month}_${year}.pdf`);
    };

    const handleExportToPng = async () => {
        const chartImageDataUrl = chartRef.current?.toBase64Image();
        if (!chartImageDataUrl) {
            throw new Error('Exporting to PNG failed');
        }
        const res = await fetch(attentionPlanExcelLogo);
        const logoBuffer = await res.arrayBuffer();
        const logoDataUrl = `data:image/png;base64,${arrayBufferToBase64(logoBuffer)}`;
        const logo = new Image();
        const chartImage = new Image();
        logo.src = logoDataUrl;
        chartImage.src = chartImageDataUrl;

        // determine logo and chart image natural dimensions
        if (!chartImage.complete) {
            await new Promise<void>((resolve, _) => {
                chartImage.onload = () => {
                    resolve();
                };
            });
        }
        if (!logo.complete) {
            await new Promise<void>((resolve, _) => {
                logo.onload = () => {
                    resolve();
                };
            });
        }
        const chartDimension = {
            width: chartImage.naturalWidth,
            height: chartImage.naturalHeight,
        };
        const logoDimension = {
            width: logo.naturalWidth,
            height: logo.naturalHeight,
        };
        const logoAspectRatio = logoDimension.width / logoDimension.height;

        const canvas = document.createElement('canvas');
        // padding of the chart, in px
        // padding top is used to present parameters
        const paddingLeft = 200;
        const paddingRight = 200;
        const paddingX = paddingLeft + paddingRight;
        const paddingBottom = 200;
        const paddingTop = 800;
        canvas.width = chartDimension.width + paddingX;
        canvas.height = chartDimension.height + paddingTop + paddingBottom;
        // It seems like there is no need for the canvas element to be present in the dom
        // document.body.appendChild(canvas);

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            throw new Error('Cannot get canvas context');
        }
        // PNG is transparent by default, draw a solid white background first
        const defaultFillStyle = ctx.fillStyle;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // restore default
        ctx.fillStyle = defaultFillStyle;

        ctx.drawImage(chartImage, paddingLeft, paddingTop);
        const drawLogoWidth = 600;
        // Keep the aspect ratio
        const drawLogoHeight = drawLogoWidth / logoAspectRatio;
        const drawLogoOffsetYBase = 100;
        // Logo to the right
        const drawLogoOffsetX = paddingLeft + chartDimension.width - drawLogoWidth;
        const drawLogoOffsetY = drawLogoOffsetYBase;
        ctx.drawImage(logo, drawLogoOffsetX, drawLogoOffsetY, drawLogoWidth, drawLogoHeight);

        const primaryTextColor = 'rgb(26, 32, 44)';
        const secondaryTextColor = 'rgb(74, 85, 104)';
        const greenTextColor = 'rgb(47, 110, 69)';
        // Title text
        ctx.fillStyle = primaryTextColor;
        const titleTextSize = 84;
        const titleFont = `bold ${titleTextSize}px/1 Inter, sans-serif`;
        ctx.font = titleFont;
        const titleTextOffsetX = paddingLeft;
        const titleTextOffsetY = drawLogoOffsetYBase + titleTextSize;
        ctx.fillText('Attention benchmarks', titleTextOffsetX, titleTextOffsetY);

        // Title helper text
        ctx.fillStyle = secondaryTextColor;
        const helperTextSize = 36;
        const titleHelperFont = `${helperTextSize}px/1 Inter, sans-serif`;
        ctx.font = titleHelperFont;
        const titleHelperGap = 24;
        const titleHelperOffsetX = paddingLeft;
        const titleHelperOffsetY = titleTextOffsetY + helperTextSize + titleHelperGap;
        const now = new Date();
        const userLocale = Intl.DateTimeFormat().resolvedOptions().locale ?? navigator.language;
        const datetimeString = new Intl.DateTimeFormat(userLocale, {
            timeZoneName: 'short',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        }).format(now);
        ctx.fillText(`Exported at ${datetimeString}`, titleHelperOffsetX, titleHelperOffsetY);

        // Parameters section
        const sectionGap = 96;
        const parameterTextSize = 56;
        const parameterFont = `${parameterTextSize}px/1 Inter, sans-serif`;
        const parameterSectionOffsetX = paddingLeft;
        const parameterSectionOffsetY = titleHelperOffsetY + sectionGap + parameterTextSize;
        ctx.font = parameterFont;
        ctx.fillStyle = greenTextColor;
        ctx.fillText('Parameters', parameterSectionOffsetX, parameterSectionOffsetY);

        const labelTextSize = 28;
        const labelFont = `${labelTextSize}px/1 Inter, sans-serif`;
        const detailTextSize = 36;
        const detailFont = `${detailTextSize}px/1 Inter, sans-serif`;
        const parameterDetailSectionGap = 48;
        const gapBetweenLabelAndText = 18;
        // countries
        ctx.fillStyle = secondaryTextColor;
        ctx.font = labelFont;
        const countryLabelOffsetX = paddingLeft;
        const countryLabelOffsetY =
            parameterSectionOffsetY + parameterDetailSectionGap + labelTextSize;
        ctx.fillText('COUNTRIES', countryLabelOffsetX, countryLabelOffsetY);

        // country details
        const countryDetailsOffsetX = paddingLeft;
        const countryDetailsOffsetY = countryLabelOffsetY + gapBetweenLabelAndText + detailTextSize;
        ctx.fillStyle = primaryTextColor;
        ctx.font = detailFont;
        let countryDetails = 'GLOBAL';
        if (!isUsingGlobal) {
            // If there are too many countries, display the abbreviation instead
            countryDetails =
                selectedCountries.length <= 7
                    ? selectedCountries.map((c) => findCountryName(c)).join(', ')
                    : selectedCountries.map((c) => c.toUpperCase()).join(', ');
        }
        ctx.fillText(countryDetails, countryDetailsOffsetX, countryDetailsOffsetY);

        const detailsSectionGap = 64;
        const ageGroupSectionOffsetX = paddingLeft;
        const genderSectionOffsetX = paddingLeft + 0.5 * chartImage.width;
        const mrcCompliantSectionOffsetX = paddingLeft + 0.75 * chartImage.width;
        const detailsSectionLabelOffsetY =
            countryDetailsOffsetY + detailsSectionGap + labelTextSize;
        const detailsSectionTextOffsetY =
            detailsSectionLabelOffsetY + gapBetweenLabelAndText + detailTextSize;
        // label
        ctx.fillStyle = secondaryTextColor;
        ctx.font = labelFont;
        ctx.fillText('AGE GROUP', ageGroupSectionOffsetX, detailsSectionLabelOffsetY);
        ctx.fillText('GENDER', genderSectionOffsetX, detailsSectionLabelOffsetY);
        ctx.fillText('MRC COMPLIANT ONLY', mrcCompliantSectionOffsetX, detailsSectionLabelOffsetY);
        // details
        ctx.fillStyle = primaryTextColor;
        ctx.font = detailFont;
        const ageGroupDetail = selectedAgeGroups.map((ag) => formatAgeGroup(ag)).join(', ');
        const genderDetail = selectedGenders.map((g) => formatStringToCapitalized(g)).join(', ');
        const mrcCompliantDetail = isMrcCompliantOnly ? 'Yes' : 'No';
        ctx.fillText(ageGroupDetail, ageGroupSectionOffsetX, detailsSectionTextOffsetY);
        ctx.fillText(genderDetail, genderSectionOffsetX, detailsSectionTextOffsetY);
        ctx.fillText(mrcCompliantDetail, mrcCompliantSectionOffsetX, detailsSectionTextOffsetY);

        canvas.toBlob(
            (blob) => {
                if (!blob) {
                    throw new Error('cannot create image');
                }
                downloadBlob(
                    blob,
                    `attentionPLAN-Attention_benchmarks_${now.getTime() / 1000}.png`,
                );
            },
            'image/png',
            1,
        );
        // document.body.removeChild(canvas);
    };

    return (
        <Container>
            <VStack align="left" spacing="0">
                <Flex flexDirection="column" gap="1rem">
                    <ContainerTitle
                        headingText={attentionDataText.heading}
                        subtitleText={attentionDataText.subtitle}
                        size="xl"
                    />
                    <Spacer />
                </Flex>
                <Tabs>
                    <Flex marginBottom="1rem">
                        <Box>
                            <Button
                                variant="outline"
                                onClick={onAdFormatModalOpen}
                                rightIcon={<Icon as={AdjustmentsHorizontalIcon} />}
                                colorScheme="orange"
                                width="100%"
                            >
                                Filter formats
                            </Button>
                            <AdFormatsBasicModal
                                scenarioAdFormats={adFormats}
                                channels={channels ?? []}
                                onConfirm={(data) => handleAdFormatConfirm(data)}
                                isOpen={isAdFormatModalOpen}
                                onClose={onAdFormatModalClose}
                                isPaidPlan={isPaidPlan}
                            />
                        </Box>
                        <Spacer />
                        <Flex alignItems="center">
                            <TabList>
                                <Tab>Table view</Tab>
                                <Tab>Chart view</Tab>
                            </TabList>
                        </Flex>
                    </Flex>
                    <Box marginLeft="-1rem" marginRight="-1rem">
                        {!isFetching ? (
                            <TabPanels>
                                <TabPanel position="relative">
                                    {/** this charts is hidden from the user, it is only used for generating the chart image */}
                                    <AttentionMetricsChart
                                        isPaidPlan={isPaidPlan}
                                        attentionData={
                                            (isPaidPlan
                                                ? attentionData
                                                : attentionData?.filter(
                                                      (_, index) => index === 0,
                                                  )) ?? []
                                        }
                                        sortBy={chartSortBy}
                                        sortDirection={chartSortDirection}
                                        boxHeight={`${(attentionData?.length ?? 2) * 5}rem`}
                                        noEntriesComponent={renderNoAttentionDataFound()}
                                        ref={chartRef}
                                        responsive={false}
                                        hidden
                                        height={
                                            isPaidPlan ? (attentionData?.length ?? 2) * 100 : 200
                                        }
                                        width={900}
                                    />
                                    {isPaidPlan ? (
                                        chunkedAttentionData.map((ad, index) => (
                                            <AttentionMetricsChart
                                                key={`AttentionMetricsChart_${index}`}
                                                id={chartIdPrefix + index}
                                                isPaidPlan={isPaidPlan}
                                                attentionData={
                                                    (ad as GetAttentionDataResponseDto[]) ?? []
                                                }
                                                sortBy={chartSortBy}
                                                sortDirection={chartSortDirection}
                                                boxHeight={`${(attentionData?.length ?? 2) * 5}rem`}
                                                noEntriesComponent={renderNoAttentionDataFound()}
                                                responsive={false}
                                                hidden
                                                height={chartChunkCount * 133}
                                                width={900}
                                                showLegend={false}
                                            />
                                        ))
                                    ) : (
                                        <AttentionMetricsChart
                                            key="AttentionMetricsChart_0"
                                            id={chartIdPrefix + 0}
                                            isPaidPlan={isPaidPlan}
                                            attentionData={
                                                attentionData?.filter((_, index) => index === 0) ??
                                                []
                                            }
                                            sortBy={chartSortBy}
                                            sortDirection={chartSortDirection}
                                            noEntriesComponent={renderNoAttentionDataFound()}
                                            responsive={false}
                                            hidden
                                            height={200}
                                            width={1200}
                                            showLegend={false}
                                        />
                                    )}
                                    <Flex
                                        justifyContent="end"
                                        alignItems="center"
                                        marginBottom="1rem"
                                    >
                                        <Menu>
                                            <MenuButton
                                                as={Button}
                                                leftIcon={<Icon as={ArrowUpTrayIcon} />}
                                                variant="outline"
                                                colorScheme="gray"
                                                backgroundColor="gray.100"
                                            >
                                                Export
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => handleExportToPdf()}>
                                                    Export to PDF
                                                </MenuItem>
                                                <MenuItem onClick={() => handleExportToExcel()}>
                                                    Export to XLSX
                                                </MenuItem>
                                                <MenuItem onClick={() => handleExportToPng()}>
                                                    Export to PNG
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Flex>
                                    <Card>
                                        <CardBody>
                                            {/* Lock Icon */}
                                            {!isPaidPlan &&
                                                attentionData &&
                                                attentionData.length > 0 && (
                                                    <Tooltip label={lockTooltip}>
                                                        <Box
                                                            position="absolute"
                                                            left="67%"
                                                            top="11.2rem"
                                                        >
                                                            <Center width="100%">
                                                                <LockIcon
                                                                    fontSize="5xl"
                                                                    color="gray.300"
                                                                />
                                                            </Center>
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                            <DataTable
                                                columns={columns}
                                                dataSource={dataSource ?? []}
                                                noEntriesComponent={renderNoAttentionDataFound()}
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPanel>
                                <TabPanel>
                                    <Flex
                                        justifyContent="end"
                                        alignItems="center"
                                        width="100%"
                                        marginBottom="1rem"
                                        gap="1rem"
                                    >
                                        {isPaidPlan && (
                                            <Flex alignItems="center">
                                                <IconButton
                                                    variant="ghost"
                                                    colorScheme="gray"
                                                    backgroundColor="gray.100"
                                                    size="sm"
                                                    aria-label="back"
                                                    onClick={() =>
                                                        setChartSortDirection(
                                                            chartSortDirection === -1 ||
                                                                chartSortDirection === 0
                                                                ? 1
                                                                : -1,
                                                        )
                                                    }
                                                    marginRight="0.5rem"
                                                    cursor="pointer"
                                                    icon={
                                                        <Icon
                                                            as={
                                                                // eslint-disable-next-line no-nested-ternary
                                                                chartSortDirection === 1
                                                                    ? ArrowUpIcon
                                                                    : chartSortDirection === -1
                                                                    ? ArrowDownIcon
                                                                    : ArrowsUpDownIcon
                                                            }
                                                        />
                                                    }
                                                />
                                                <FormLabel marginTop="0.4rem">Sort by</FormLabel>
                                                <Select
                                                    width="max-content"
                                                    backgroundColor="white"
                                                    onChange={(e) =>
                                                        handleChartSort(
                                                            e.target.value as ChartSortOptions,
                                                        )
                                                    }
                                                >
                                                    {chartSortOptions.map((o, index) => (
                                                        <option
                                                            value={o.value}
                                                            key={`sortOption_${index}`}
                                                        >
                                                            {o.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </Flex>
                                        )}
                                        <Menu>
                                            <MenuButton
                                                as={Button}
                                                rightIcon={<ChevronDownIcon />}
                                                variant="outline"
                                                colorScheme="gray"
                                                backgroundColor="gray.100"
                                            >
                                                Export
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => handleExportToPdf()}>
                                                    Export to PDF
                                                </MenuItem>
                                                <MenuItem onClick={() => handleExportToExcel()}>
                                                    Export to XLSX
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Flex>
                                    <Card>
                                        <CardBody>
                                            <AttentionMetricsChart
                                                isPaidPlan={isPaidPlan}
                                                attentionData={attentionData ?? []}
                                                sortBy={chartSortBy}
                                                sortDirection={chartSortDirection}
                                                boxHeight={`${(attentionData?.length ?? 2) * 5}rem`}
                                                noEntriesComponent={renderNoAttentionDataFound()}
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPanel>
                            </TabPanels>
                        ) : (
                            <Card marginX="1rem">
                                <CardBody>
                                    <Stack>
                                        <Skeleton height="1.5rem" />
                                        <Skeleton height="1.5rem" />
                                        <Skeleton height="1.5rem" />
                                        <Skeleton height="1.5rem" width="80%" />
                                    </Stack>
                                </CardBody>
                            </Card>
                        )}
                    </Box>
                </Tabs>
            </VStack>
        </Container>
    );
};
