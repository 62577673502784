import React, {FC, useEffect, useState} from 'react'
import {
    Button,
    Center,
    Container,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Spacer,
    Stack, Tooltip,
    VStack
} from "@chakra-ui/react";
import {
    ExpandableBanner,
    ExpandableBannerBody,
    ExpandableBannerHeader
} from "@components/atoms/expandableBanner/ExpandableBanner";
import {CalendarIcon, MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {MultiSelect} from "@apps/attentionADJUST/components/molecules";
import {useNavigate} from "react-router-dom";
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";
import {GlobalCampaignHeaderSection} from "@components/organisms";
import ReactDatePicker from "react-datepicker";
import {routing} from "@configs";

export interface ProveDashboardSearchCriteriaProps {
    selectedLineItems: Array<ProveLineItem>;
    startDate: Date;
    endDate: Date;
}

export interface ProveDashboardHeaderProps {
    globalCampaign: GlobalCampaign
    lineItems: Array<ProveLineItem>

    setDashboardSearchCriteria(searchCriteria: ProveDashboardSearchCriteriaProps): void
}

export interface SelectedLineItemOption {
    label: string
    value: string
}

export const ProveDashboardHeader: FC<ProveDashboardHeaderProps> = ({
                                                                        globalCampaign,
                                                                        lineItems,
                                                                        setDashboardSearchCriteria
                                                                    }) => {

    const isDemoCampaign = globalCampaign.id === 'demo';

    const navigate = useNavigate();
    const {t} = useTranslation('prove');
    const content = t('prove.dashboard', {
        returnObjects: true,
    });

    const formatOptionsForSelect = (
        items: Array<ProveLineItem>
    ): Array<SelectedLineItemOption> => {
        return items.map((item) => ({
            label: item.name,
            value: item.id!
        })).sort((a, b) => (a.label < b.label ? -1 : 1));
    }

    const [selectedLineItemOptions, setSelectedLineItemOptions] = useState(formatOptionsForSelect(lineItems));
    const [startDate, setStartDate] = useState(undefined as Date | undefined);
    const [endDate, setEndDate] = useState(undefined as Date | undefined);
    const [lastPossibleEndDate, setLastPossibleEndDate] = useState(undefined as Date | undefined);
    const [isValid, setIsValid] = useState(false);

    const resolveSelectedLineItems = () => {
        const selectedLineItems = [];

        for (let i = 0; i < selectedLineItemOptions.length; i++) {
            const selectedLineItemId = selectedLineItemOptions[i].value;

            for (let j = 0; j < lineItems.length; j++) {
                if (lineItems[j].id! === selectedLineItemId) {
                    selectedLineItems.push(lineItems[j]);
                }
            }
        }

        return selectedLineItems;
    }

    useEffect(() => {
        const isValidLineItems = (selectedLineItemOptions && selectedLineItemOptions.length > 0);
        const isValidDateRange = (!!startDate && !!endDate);

        setIsValid(isValidLineItems && isValidDateRange);
    }, [startDate, endDate, selectedLineItemOptions]);

    useEffect(() => {
        const campaignStartDate = moment(globalCampaign.startDate);
        const campaignEndDate = moment(globalCampaign.endDate);

        const now = moment();

        const selectedEndDate = now.isAfter(campaignEndDate) ? campaignEndDate.toDate() : now.toDate();

        const previousSevenDays = moment(selectedEndDate).subtract(7, "days");
        const selectedStartDate = previousSevenDays.isAfter(campaignStartDate) ? previousSevenDays.toDate() : campaignStartDate.toDate();

        setLastPossibleEndDate(selectedEndDate);
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);

        setDashboardSearchCriteria({
            selectedLineItems: resolveSelectedLineItems(),
            startDate: selectedStartDate,
            endDate: selectedEndDate
        });
    }, []);

    const onDatesChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;

        setStartDate(start!);
        setEndDate(end!);
    }

    return (
        <ExpandableBanner>
            <ExpandableBannerHeader>
                <Container>
                    <HStack justifyContent="space-between">
                        <GlobalCampaignHeaderSection
                            campaign={globalCampaign}
                            allowEdits={false}
                        />
                        {!isDemoCampaign && (
                            <Button
                                colorScheme="green"
                                onClick={() => navigate(`/${routing.prove.root.path}/${globalCampaign.id}/${routing.prove.manage.path}`)}>
                                {content.buttons.editLineItems}
                            </Button>
                        )}
                    </HStack>
                </Container>
            </ExpandableBannerHeader>
            <ExpandableBannerBody>
                <Container>
                    <Stack gap="1rem">
                        <Grid templateColumns="400px 300px 110px" gap="1rem">
                            <GridItem>
                                <MultiSelect
                                    label={content.search.lineItems.label}
                                    options={formatOptionsForSelect(
                                        lineItems,
                                    ).map((o) => ({
                                        isDisabled: false,
                                        showLockIcon: false,
                                        ...o,
                                    }))}
                                    setSelectedOptions={setSelectedLineItemOptions}
                                    selectedOptions={selectedLineItemOptions}
                                    isRequired
                                    isClearable
                                    selectAll
                                    allowSelectAll
                                />
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>{content.search.dateRange.label}</FormLabel>
                                    <Popover>
                                        <PopoverTrigger>
                                            <InputGroup>
                                                <Input backgroundColor="white"
                                                       value={(endDate) ? `${startDate?.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : `${startDate?.toLocaleDateString()}`}
                                                       onChange={() => {
                                                       }}
                                                />
                                                <InputRightElement pointerEvents="none" color="gray.500">
                                                    <Icon boxSize="1.5rem" as={CalendarIcon}/>
                                                </InputRightElement>
                                            </InputGroup>
                                        </PopoverTrigger>
                                        <Portal>
                                            <PopoverContent minW={{base: "100%", lg: "max-content"}}>
                                                <PopoverBody>
                                                    <ReactDatePicker
                                                        portalId="root-portal"
                                                        selected={startDate}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsRange
                                                        showPopperArrow={false}
                                                        onChange={onDatesChange}
                                                        minDate={moment(globalCampaign.startDate).toDate()}
                                                        maxDate={lastPossibleEndDate}
                                                        monthsShown={2}
                                                        inline
                                                    />
                                                    <Center>
                                                        <HStack>
                                                            <Button
                                                                size={"xs"}
                                                                onClick={() => onDatesChange([lastPossibleEndDate!, lastPossibleEndDate!])}
                                                            >
                                                                {content.search.dateRange.last24Hours}
                                                            </Button>
                                                            <Button
                                                                size={"xs"}
                                                                onClick={() => {
                                                                    const campaignStartDate = moment(globalCampaign.startDate);
                                                                    const adjustedStartDate = moment(lastPossibleEndDate).subtract(1, "days");

                                                                    const selectedDate = (adjustedStartDate.isBefore(campaignStartDate)) ? campaignStartDate.toDate() : adjustedStartDate.toDate();

                                                                    onDatesChange([selectedDate, lastPossibleEndDate!])
                                                                }}
                                                            >
                                                                {content.search.dateRange.last48Hours}
                                                            </Button>
                                                            <Button
                                                                size={"xs"}
                                                                onClick={() => {
                                                                    const campaignStartDate = moment(globalCampaign.startDate);
                                                                    const adjustedStartDate = moment(lastPossibleEndDate).subtract(7, "days");

                                                                    const selectedDate = (adjustedStartDate.isBefore(campaignStartDate)) ? campaignStartDate.toDate() : adjustedStartDate.toDate();

                                                                    onDatesChange([selectedDate, lastPossibleEndDate!])
                                                                }}
                                                            >
                                                                {content.search.dateRange.last7Days}
                                                            </Button>
                                                            <Button
                                                                size={"xs"}
                                                                onClick={() => {
                                                                    const campaignStartDate = moment(globalCampaign.startDate);
                                                                    const adjustedStartDate = moment(lastPossibleEndDate).subtract(30, "days");

                                                                    const selectedDate = (adjustedStartDate.isBefore(campaignStartDate)) ? campaignStartDate.toDate() : adjustedStartDate.toDate();

                                                                    onDatesChange([selectedDate, lastPossibleEndDate!])
                                                                }}
                                                            >
                                                                {content.search.dateRange.last30Days}
                                                            </Button>
                                                        </HStack>
                                                    </Center>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Portal>
                                    </Popover>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <VStack height={"100%"}>
                                    <Spacer/>
                                    <Tooltip
                                        isDisabled={!isDemoCampaign}
                                        label="Data filtering is not available on demo campaign dashboard"
                                    >
                                        <Button
                                            colorScheme="orange"
                                            isDisabled={isDemoCampaign || !isValid}
                                            leftIcon={<Icon as={MagnifyingGlassIcon}/>}
                                            onClick={() => {
                                                setDashboardSearchCriteria({
                                                    selectedLineItems: resolveSelectedLineItems(),
                                                    startDate: startDate!,
                                                    endDate: endDate!
                                                });
                                            }}
                                            loadingText={content.search.button}
                                        >
                                            {content.search.button}
                                        </Button>
                                    </Tooltip>
                                </VStack>
                            </GridItem>
                        </Grid>
                    </Stack>
                </Container>
            </ExpandableBannerBody>
        </ExpandableBanner>
    )
}
