/**
 * Checks if a number is equal to another within a given margin of error
 * @param x
 * @param y
 * @param epsilon margin of error, defaults to 0.01 (ie within 2 decimal places)
 * @returns
 */
export const isAlmostEqual = (x: number, y: number, epsilon = 0.01) => {
    return Math.abs(x - y) < epsilon;
};

export const getNumberRange = (start: number, end: number, step?: number) => {
    step = step || 1;

    let arr = [];
    for (let i = start; i <= end; i += step) {
        arr.push(i);
    }

    return arr;
};
