import { fonts } from '@assets/design-system/theming/typography';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const premiumTextStyling = {
    color: 'gray.600',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
};

export const Text: ComponentStyleConfig = {
    baseStyle: {
        fontFamily: fonts.body,
    },
    variants: {
        premium: premiumTextStyling,
    },
    defaultProps: {
        color: 'gray.800',
    },
};
