import { CacheKey } from '@shared/cores/constants';
import { StringType } from '@shared/cores/types';
import Cookies from 'js-cookie';
import posthog from 'posthog-js';
import { OrganisationResponseDto, UserResponseDto } from '@api-clients/account-manager';

export const formatTime = (date: Date): string => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours ?? 12;
    let minuteString = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minuteString} ${ampm}`;
};

export const clearLocalStorage = () => {
    const keys = [CacheKey.User, CacheKey.Organisation, CacheKey.Subscription];
    keys.forEach((key) => {
        window.localStorage.removeItem(key);
    });
};

export const getListRenderKey = (index: number, value: any) => [index, value].join('_');

export const isValidValueString = (value: StringType) =>
    value !== undefined && value !== null && value !== '';

export const isUnauthorized = (error: any) => error.response.status === 401;

/**
 * Deletes the default Google Analytics cookies (performance cookies).
 * This is used when a user opts out of Google Analytics.
 */
export const removePerformanceCookies = (): void => {
    Cookies.remove('_ga', { path: '/', domain: document.domain });
    Cookies.remove('_gid', { path: '/', domain: document.domain });
    Cookies.remove('_gat', { path: '/', domain: document.domain });
};

/**
regex that conforms to the following ruleset
8 characters minimum
1 lowercase letter
1 uppercase letter
1 number
1 special character. Example - @, $, #, etc.
 */
export const passwordRequirementsRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const sendEventToPostHog = (eventName: string, properties: Record<string, any>) => {
    posthog.capture(eventName, properties);
};

export const customPostHogEventProperties = (
    organisation: OrganisationResponseDto | null,
    user: UserResponseDto | null,
) => {
    return organisation && user
        ? {
              organisation_id: organisation.id!,
              organisation_name: organisation.organisationName!,
              user_id: user.id!,
              user_name: `${user.firstName!} ${user.lastName!}`,
          }
        : { properties: 'undefined' };
};

/**
 * Download in memory blob from browser
 * @param blob Blob object in the browser's memory
 * @param name default name of the file
 */
export const downloadBlob = (blob: Blob, name: string) => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        }),
    );

    // Remove link from body
    document.body.removeChild(link);
};


export const isNumeric = (str: any) => {
    if (typeof str === 'number' || typeof str === 'bigint') {
        return true;
    }
    if (typeof str !== 'string') {
        return false; // dealing with string only
    }
    // Number.isNaN's behavior is different from isNaN, try input "1234abc"
    // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    // eslint-disable-next-line
    return !isNaN(str as unknown as number) && !isNaN(parseFloat(str));
}

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export const copyCurrentUrl = () => {
    navigator.clipboard.writeText(window.location.href)
}