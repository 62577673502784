import {
    Button,
    HStack,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text
} from '@chakra-ui/react';
import { FC } from 'react';

export interface ProveAnnouncementModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const ProveAnnouncementModal: FC<ProveAnnouncementModalProps> = ({ isOpen, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <>
            <Modal size="4xl" isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader margin="1rem">
                        We're thrilled to announce a powerful new addition to our platform:
                        attentionPROVE!
                    </ModalHeader>
                    <ModalBody>
                        <Stack marginRight="1rem" marginLeft="1rem">
                            <Image
                                marginBottom="1rem"
                                marginX="10rem"
                                src="/prove_example_image.png"
                                alt="prove example"
                            />
                            <Text>
                                Introducing attentionPROVE, the ultimate solution for measuring and
                                optimising your live media campaigns with attention data. With
                                attentionPROVE seamlessly integrated into our platform, you can now
                                elevate your media planning and measuring experience to new heights.
                            </Text>
                            <Text>
                                Talk to us{' '}
                                <Link
                                    href={'https://share.hsforms.com/1sEM35U5qT_23iSAn2rYqCAcs4ay'}
                                    isExternal
                                >
                                    here
                                </Link>{' '}
                                to see how attentionPROVE can help you drive smarter media buying
                                and stronger ROI.
                            </Text>
                        </Stack>
                        <HStack mt={8} spacing={4}>
                            <Spacer />
                            <Button onClick={handleClose} colorScheme="gray">
                                Close
                            </Button>
                        </HStack>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
        </>
    );
};
