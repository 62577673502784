import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
} from '@chakra-ui/react';
import {ArrowRightIcon,} from '@heroicons/react/24/solid';
import {usePosthogEvent} from '@hooks/_contexts/app/usePosthog';
import React, {FC, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from "@redux";
import {useApiConfiguration} from "@hooks/configuration";
import {DateRange, DateRangeFormParam} from "@components/molecules/dateRange/DateRange";
import moment from "moment";
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";
import {useTranslation} from "react-i18next";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {createGlobalCampaignAsync, updateGlobalCampaignAsync} from "@redux/slices/global/campaign/thunks";
import {useNavigate} from "react-router-dom";
import {routing} from "@configs";
import {DynamicUpgradeModal} from "@components/molecules";
import {UpgradeMediaPlanLimitHeader} from "@apps/attentionADJUST/components/atoms";
import {FeatureCode, LimitType} from "@api-clients/subscriptions";
import {useAppContextHelper} from "@hooks/_contexts";
import {EnableProveModal} from "@components/molecules/modals/enableProveModal/EnableProveModal";

interface FormData {
    name: string;
    startDate: Date;
    endDate: Date;
    allocatedBudget: number;
}

export interface CampaignDetailsProps {
    globalCampaigns: GlobalCampaign[];
    selectedCampaign: GlobalCampaign | undefined;
    activatePlanOnCreate: () => void;
    activateProveOnCreate: () => void;
}

export const GlobalCampaignDetails: FC<CampaignDetailsProps> = ({
                                                                    globalCampaigns,
                                                                    selectedCampaign,
                                                                    activatePlanOnCreate,
                                                                    activateProveOnCreate
                                                                }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getGlobalCampaignServerConfig} = useApiConfiguration();

    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFormParam>({
        startDate: (selectedCampaign && !!selectedCampaign.startDate) ? moment.utc(selectedCampaign.startDate).toDate() : null,
        endDate: (selectedCampaign && !!selectedCampaign.endDate) ? moment.utc(selectedCampaign.endDate).toDate() : null,
        isValid: (selectedCampaign !== undefined && !!selectedCampaign.startDate! && !!selectedCampaign.endDate!),
    });

    const {t} = useTranslation('campaign');
    const content = t('campaign.details', {
        returnObjects: true,
    });

    const containerRef = useRef<HTMLDivElement>(null);

    // TODO: how is Posthog used and do we need to setup events for each user interaction?
    const emitPosthogCreateCampaignEvent = usePosthogEvent('Create new campaign');
    const emitPosthogUpdateCampaignEvent = usePosthogEvent('Update existing campaign');

    const {
        handleSubmit,
        register,
        formState: {errors, isValid},
    } = useForm<FormData>({
        defaultValues: {
            name: selectedCampaign?.name
        },
        mode: 'onChange'
    });

    const onFormSave = async (data: FormData) => {
        const configuration = await getGlobalCampaignServerConfig();

        const formattedStartDate = moment.utc(selectedDateRange.startDate!).format("YYYY-MM-DD");
        const formattedEndDate = moment.utc(selectedDateRange.endDate!).format("YYYY-MM-DD");

        if (!!selectedCampaign?.name &&
            !!selectedCampaign?.startDate &&
            !!selectedCampaign?.endDate) {

            const needToUpdateCampaign = (
                selectedCampaign.name !== data.name ||
                selectedCampaign.startDate !== formattedStartDate ||
                selectedCampaign.endDate !== formattedEndDate
            );

            if (needToUpdateCampaign) {
                dispatch(updateGlobalCampaignAsync({
                    configuration, campaign: {
                        ...selectedCampaign,
                        name: data.name,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                    }
                }));

                emitPosthogUpdateCampaignEvent();

                navigate(`/${routing.campaign.root.path}`);
            }
        } else {
            dispatch(createGlobalCampaignAsync({
                configuration, campaign: {
                    name: data.name,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                }
            }));

            emitPosthogCreateCampaignEvent();
        }
    };

    const isFormInvalid = !selectedDateRange.isValid || !isValid;

    const {
        currentContextValues: {featureBalances},
    } = useAppContextHelper();

    const {
        isOpen: isUpgradeModalOpen,
        onOpen: onUpgradeModalOpen,
        onClose: onUpgradeModalClose,
    } = useDisclosure();

    const {
        isOpen: isEnableProveModalOpen,
        onOpen: onEnableProveModalOpen,
        onClose: onEnableProveModalClose,
    } = useDisclosure();

    const createMediaPlanFeature = featureBalances?.find(
        (f) => f.featureCode === FeatureCode.MediaPlansCreateMediaPlan,
    );

    const canUserCreateCampaigns =
        createMediaPlanFeature && createMediaPlanFeature.limit! - createMediaPlanFeature.used! > 0;

    const canUserEnableProve = featureBalances?.find(
        (f) => f.featureCode === FeatureCode.ProveSaasAccess,
    ) !== undefined;

    const isPaidPlan =
        featureBalances?.find(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansViewScenario &&
                f.limitType === LimitType.Unlimited,
        ) ?? false;

    function isUniqueName(value: string) {
        if (selectedCampaign && selectedCampaign.name === value) {
            return true;
        }

        for (let i=0; i<globalCampaigns.length; i++) {
            if (globalCampaigns[i].name === value) {
                return false;
            }
        }

        return true;
    }

    return <>
        <DynamicUpgradeModal
            key="DynamicUpgradeModal_CampaignManagementPage"
            isOpen={isUpgradeModalOpen}
            onClose={onUpgradeModalClose}
            header={<UpgradeMediaPlanLimitHeader/>}
        />
        <EnableProveModal
            key="EnableProveModal_GlobalCampaignDetails"
            isOpen={isEnableProveModalOpen}
            onClose={onEnableProveModalClose}
        />
        <form>
            <Box paddingX="3rem" paddingY="2rem">
                <Flex
                    flexDirection="column"
                    gap="1rem"
                    maxW="42rem"
                    mx="auto"
                >
                    <Box>
                        <FormControl
                            isRequired
                            isInvalid={
                                errors.name !== undefined
                            }
                        >
                            <FormLabel>{content.form.name.label}</FormLabel>
                            <Input
                                {...register('name', {
                                    required: {
                                        value: true,
                                        message: content.form.name.requiredMessage
                                    },
                                    validate: (value) => (isUniqueName(value.trim())) ? true : content.form.name.duplicateMessage,
                                    maxLength: {
                                        value: 100,
                                        message: content.form.name.errorMessage
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.name &&
                                    (errors.name.message as string)}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <DateRange
                        dateRangeForm={selectedDateRange}
                        onChange={setSelectedDateRange}
                        startDateRequired
                        endDateRequired
                    />
                    <Divider my="1.5rem"/>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    >
                        {selectedCampaign?.id ? (
                            <Button
                                onClick={() => {
                                    containerRef.current?.scrollIntoView(
                                        {
                                            behavior: 'smooth',
                                            block: 'start',
                                        },
                                    );

                                    handleSubmit(onFormSave)();
                                }}
                                rightIcon={
                                    <Icon as={ArrowRightIcon}/>
                                }
                                isDisabled={isFormInvalid}
                                type="submit"
                                display="flex"
                                alignItems="center"
                            >
                                {content.saveButton}
                            </Button>
                        ) : (
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    rightIcon={<ChevronDownIcon/>}
                                    isDisabled={isFormInvalid}
                                >
                                    Save
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => {
                                        if (canUserCreateCampaigns) {
                                            activatePlanOnCreate();
                                            handleSubmit(onFormSave)();
                                        } else {
                                            onUpgradeModalOpen();
                                        }
                                    }}>
                                        Enable PLAN
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        if (canUserEnableProve) {
                                            activateProveOnCreate();
                                            handleSubmit(onFormSave)();
                                        } else {
                                            onEnableProveModalOpen();
                                        }
                                    }}>
                                        Enable PROVE
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        )}
                    </Box>
                </Flex>
            </Box>
        </form>
    </>
};
