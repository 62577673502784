export const tableLabels = {
    memberList: {
        columns: {
            fullName: 'Full name',
            email: 'Email',
            accessLevel: 'Access level',
        },
    },
    adClientList: {
        columns: {
            clientName: 'Client name',
            primaryContactName: 'Primary contact',
        },
    },
    mediaPlanService: {
        columns: {
            campaignsLeft: 'Campaigns left',
            countryAccess: 'Country access',
        },
    },
    mediaPlanApiService: {
        columns: {
            attentionDataCountryDataAccess: 'Attention data country access',
            aarcCountryDataAccess: 'Attention-adjusted reach curve',
        },
    },
};
