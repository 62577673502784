import {
    OrganisationResponseDto,
    OrganisationResponseDtoFromJSON,
    OrganisationResponseDtoToJSON,
    UserResponseDto,
    UserResponseDtoFromJSON,
    UserResponseDtoToJSON,
} from '@api-clients/account-manager';
import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import { useLocalStorage } from '@hooks/localStorage';
import { CacheKey } from '@shared/cores/constants';
import {
    FeatureBalanceResponseDto,
    FeatureBalanceResponseDtoFromJSON,
    FeatureBalanceResponseDtoToJSON,
} from '@api-clients/subscriptions';
import {
    AppTours,
    ProductToursFromJSON,
    ProductToursToJSON,
} from '@components/organisms/digitalOnboarding';

export interface AppCookie {
    accepted: boolean;
}

interface ContextCookies {
    strict: ContextProperty<AppCookie>;
    performance: ContextProperty<AppCookie>;
}

interface ErrorFetching {
    isError: boolean | null;
}

export interface ContextProperty<T> {
    currentValue: T | null;
    setCurrentValue: (value: T | null) => void;
}

interface NewAppContextProps {
    appCookies: ContextCookies;
    appUser: ContextProperty<UserResponseDto>;
    appOrganisation: ContextProperty<OrganisationResponseDto>;
    appFeatureBalances: ContextProperty<Array<FeatureBalanceResponseDto>>;
    errorFetching: ContextProperty<ErrorFetching>;
    productTours: ContextProperty<AppTours>;
}

export const initialState: NewAppContextProps = {
    appCookies: {
        strict: {
            currentValue: {
                accepted: true,
            },
            setCurrentValue: (cookie: AppCookie | null) => {},
        },
        performance: {
            currentValue: {
                accepted: false,
            },
            setCurrentValue: (cookie: AppCookie | null) => {},
        },
    },
    appUser: {
        currentValue: null,
        setCurrentValue: (user: UserResponseDto | null) => {},
    },
    appOrganisation: {
        currentValue: null,
        setCurrentValue: (organisation: OrganisationResponseDto | null) => {},
    },
    appFeatureBalances: {
        currentValue: null,
        setCurrentValue: (featureBalances: Array<FeatureBalanceResponseDto> | null) => {},
    },
    errorFetching: {
        currentValue: { isError: false },
        setCurrentValue: (state: ErrorFetching | null) => {},
    },
    productTours: {
        currentValue: null,
        setCurrentValue: (tours: AppTours | null) => {},
    },
};

export const AppContext = createContext<NewAppContextProps>(initialState);

interface ContextProviderProps {
    children: ReactNode;
}

export const AppContextProvider: FC<ContextProviderProps> = ({ children }) => {
    // From local storage
    const [cachedUser, setCachedUser] = useLocalStorage(CacheKey.User);
    const [cachedOrganisation, setCachedOrganisation] = useLocalStorage(CacheKey.Organisation);
    const [cachedStrictCookies, setCachedStrictCookies] = useLocalStorage(
        CacheKey.StrictlyNecessaryCookies,
    );
    const [cachedPerformanceCookies, setCachedPerformanceCookies] = useLocalStorage(
        CacheKey.PerformanceCookies,
    );
    const [cachedFeatureBalances, setCachedFeatureBalances] = useLocalStorage(
        CacheKey.FeatureBalances,
    );
    const [cachedProductTours, setCachedProductTours] = useLocalStorage(CacheKey.ProductTours);

    // Local states
    const [localUser, setLocalUser] = useState<UserResponseDto | null>(
        UserResponseDtoFromJSON(cachedUser),
    );
    const [localOrganisation, setLocalOrganisation] = useState<OrganisationResponseDto | null>(
        OrganisationResponseDtoFromJSON(cachedOrganisation),
    );
    const [localStrictCookie, setLocalStrictCookie] = useState<AppCookie | null>(
        cachedStrictCookies,
    );
    const [localPerformanceCookie, setLocalPerformanceCookie] = useState<AppCookie | null>(
        cachedPerformanceCookies,
    );
    const [localErrorFetching, setLocalErrorFetching] = useState<boolean | null>(false);
    const [localFeatureBalances, setLocalFeatureBalances] =
        useState<Array<FeatureBalanceResponseDto> | null>(
            cachedFeatureBalances?.map((f: any) => FeatureBalanceResponseDtoFromJSON(f)) ?? null,
        );
    const [localProductTours, setLocalProductTours] = useState<AppTours | null>(
        ProductToursFromJSON(cachedProductTours),
    );

    // Return values
    const ctxUser: ContextProperty<UserResponseDto> = useMemo(
        () => ({
            currentValue: localUser,
            setCurrentValue: (data: UserResponseDto | null) => {
                setCachedUser(UserResponseDtoToJSON(data));
                setLocalUser((val) => data);
            },
        }),
        [localUser],
    );

    const ctxOrganisation: ContextProperty<OrganisationResponseDto> = useMemo(
        () => ({
            currentValue: localOrganisation,
            setCurrentValue: (data: OrganisationResponseDto | null) => {
                setCachedOrganisation(OrganisationResponseDtoToJSON(data));
                setLocalOrganisation((val) => data);
            },
        }),
        [localOrganisation],
    );

    const ctxCookies: ContextCookies = useMemo(
        () => ({
            strict: {
                currentValue: localStrictCookie,
                setCurrentValue: (cookie: AppCookie | null) => {
                    setCachedStrictCookies(cookie);
                    setLocalStrictCookie(cookie);
                },
            },
            performance: {
                currentValue: localPerformanceCookie,
                setCurrentValue: (cookie: AppCookie | null) => {
                    setCachedPerformanceCookies(cookie);
                    setLocalPerformanceCookie(cookie);
                },
            },
        }),
        [localStrictCookie, localPerformanceCookie],
    );

    const ctxErrorFetching: ContextProperty<ErrorFetching> = useMemo(
        () => ({
            currentValue: {
                isError: localErrorFetching,
            },
            setCurrentValue: (state: ErrorFetching | null) => {
                setLocalErrorFetching(state!.isError);
            },
        }),
        [localErrorFetching],
    );

    const ctxFeatureBalances: ContextProperty<Array<FeatureBalanceResponseDto>> = useMemo(
        () => ({
            currentValue: localFeatureBalances,
            setCurrentValue: (data: Array<FeatureBalanceResponseDto> | null) => {
                setCachedFeatureBalances(
                    // never store undefined to localStorage, undefined is not valid JSON, but null is valid JSON
                    data?.map((f) => FeatureBalanceResponseDtoToJSON(f)) ?? null,
                );
                setLocalFeatureBalances((val) => data);
            },
        }),
        [localFeatureBalances],
    );

    const ctxProductTours: ContextProperty<AppTours> = useMemo(
        () => ({
            currentValue: localProductTours,
            setCurrentValue: (tour: AppTours | null) => {
                setCachedProductTours(ProductToursToJSON(tour));
                setLocalProductTours(tour);
            },
        }),
        [localProductTours],
    );

    const value = useMemo(
        (): NewAppContextProps => ({
            appCookies: ctxCookies,
            appUser: ctxUser,
            appOrganisation: ctxOrganisation,
            errorFetching: ctxErrorFetching,
            appFeatureBalances: ctxFeatureBalances,
            productTours: ctxProductTours,
        }),
        [
            ctxCookies,
            ctxUser,
            ctxOrganisation,
            ctxErrorFetching,
            ctxFeatureBalances,
            ctxProductTours,
        ],
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = (): NewAppContextProps => useContext(AppContext);
