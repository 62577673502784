import {
    Badge,
    Center,
    Heading,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import {Column, DataTable, Row} from '@components/molecules';
import {ReactJSXElement} from '@emotion/react/types/jsx-namespace';
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {ProveAdFormat} from "@api-clients/prove/schema/ProveAdFormat";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";
import {CopyIcon, RepeatIcon} from "@chakra-ui/icons";
import {useDispatch} from "@redux";
import {
    generateProveLineItemJsTagAsync,
    generateProveLineItemVastLinearWrapperTagAsync
} from "@redux/slices/prove/lineItem/thunks";
import {useApiConfiguration} from "@hooks/configuration";
import {ProveLineItemVastModalBody} from "@components/organisms/prove/ProveLineItemVastModalBody";
import {UserWithAccessLevelDto} from "@api-clients/account-manager";
import {useAppContextHelper} from "@hooks/_contexts";
import {EllipsisVerticalIcon, PencilSquareIcon} from "@heroicons/react/24/outline";

export interface LineItemManagementSectionProps {
    selectedCampaign: ProveCampaign;
    availableAdFormats: Array<ProveAdFormat>;
    lineItems: Array<ProveLineItem>;
    users: Array<UserWithAccessLevelDto>;
}

interface DataRow {
    name: string;
    adFormat: string;
    status: string;
    createdAt: Date;
    createdBy: string;
    originalLineItem: ProveLineItem;
}

export const ProveLineItemsTable: FC<LineItemManagementSectionProps> = ({
                                                                            selectedCampaign,
                                                                            availableAdFormats,
                                                                            lineItems,
                                                                            users,
                                                                        }) => {
    const dispatch = useDispatch();
    const [isLineItemVastModalOpen, setIsLineItemVastModalOpen] = useState(false);
    const [selectedLineItem, setSelectedLineItem] = useState(undefined as ProveLineItem | undefined);
    const {getProveManagementServiceConfig, getProveTagServiceConfig} = useApiConfiguration();
    const {t} = useTranslation('prove');
    const content = t('prove.lineItems', {
        returnObjects: true,
    });
    const {
        helper: {resolveProveAdFormatInstance, resolveProveAdFormatName},
    } = useAppContextHelper();

    const getUserNameFromId = (id?: string | null) => {
        const currentUser = users.find((user) => user.id === id);

        return (currentUser) ? `${currentUser?.firstName} ${currentUser?.lastName}` : content.existing.table.unknown;
    };

    // we need to keep this functionality so existing line items can still generate tags
    const generateTag = async (lineItem: ProveLineItem) => {
        const adFormat = resolveProveAdFormatInstance(availableAdFormats, lineItem.adFormat);
        const managementConfiguration = await getProveManagementServiceConfig();
        const tagConfiguration = await getProveTagServiceConfig();

        if (adFormat.mediaType === 'display') {
            dispatch(generateProveLineItemJsTagAsync({
                managementConfiguration,
                tagConfiguration,
                campaign: selectedCampaign,
                lineItem,
            }));
        } else if (adFormat.mediaType === 'video') {
            dispatch(generateProveLineItemVastLinearWrapperTagAsync({
                managementConfiguration,
                tagConfiguration,
                campaign: selectedCampaign,
                lineItem,
            }));
        }
    }

    const resolveStatusConfig = (status: string) => {
        if (status === 'TAG_REQUIRED') {
            return {
                label: content.existing.table.status.tagRequired,
                colorScheme: "orange"
            }
        }

        if (status === 'VAST_REQUIRED') {
            return {
                label: content.existing.table.status.vastRequired,
                colorScheme: "red"
            }
        }

        return {
            label: content.existing.table.status.ready,
            colorScheme: "green"
        }
    }

    const handleStatus = (status: string) => {
        const statusConfig = resolveStatusConfig(status);

        return (
            <Badge
                colorScheme={statusConfig.colorScheme}
                variant="solid"
                textTransform="inherit"
                fontSize="0.75rem"
                rounded="0.3rem"
            >
                {statusConfig.label}
            </Badge>
        )
    }

    const {
        isOpen: isLineItemTooltipOpen,
        onOpen: onLineItemTooltipOpen,
        onClose: onLineItemTooltipClose
    } = useDisclosure();

    const columns: Array<Column<DataRow>> = [
        {
            title: content.existing.table.headers.name,
            dataIndex: 'name',
            key: 'name',
            render: (row) => {
                return (
                    <Tooltip label={row.name}>
                        <Text maxWidth={500} isTruncated>{row.name}</Text>
                    </Tooltip>
                );
            },
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: content.existing.table.headers.adFormat,
            dataIndex: 'adFormat',
            key: 'adFormat',
            render: (row) => {
                return (
                    <Text noOfLines={1}>
                        {resolveProveAdFormatName(availableAdFormats, row.adFormat)}
                    </Text>
                );
            },
            sorter: (a, b) =>
                b.adFormat.localeCompare(a.adFormat),
        },
        {
            title: content.existing.table.headers.dateCreated,
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) =>
                b.createdAt.toLocaleDateString().localeCompare(a.createdAt.toLocaleDateString()),
            render: (row) => <>{row.createdAt.toLocaleDateString()}</>,
        },
        {
            title: content.existing.table.headers.owner,
            dataIndex: 'createdBy',
            key: 'owner',
            sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        },
        {
            title: content.existing.table.headers.status,
            dataIndex: 'status',
            key: 'status',
            render: (row) => {
                return (
                    <>
                        {handleStatus(row.status)}
                    </>
                );
            }
        },
        {
            title: content.existing.table.headers.action,
            key: 'action',
            render: (row) => {
                return (
                    <HStack spacing={2}>
                        <Menu>
                            <Tooltip isOpen={isLineItemTooltipOpen}>
                                <MenuButton
                                    color="gray.400"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    as={IconButton}
                                    isRound
                                    variant="ghost"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    icon={<EllipsisVerticalIcon/>}
                                    size="sm"
                                />
                            </Tooltip>
                            <MenuList minH="48px">
                                {resolveProveAdFormatInstance(availableAdFormats, row.adFormat).mediaType === 'video' && (
                                    <MenuItem
                                        onClick={() => {
                                            setSelectedLineItem(row.originalLineItem);
                                            setIsLineItemVastModalOpen(true);
                                        }}
                                        icon={
                                            <Center>
                                                <Icon as={PencilSquareIcon} boxSize="1.5rem"/>
                                            </Center>
                                        }
                                    >
                                        {content.existing.table.tooltip.modifyVast}
                                    </MenuItem>
                                )}
                                {row.status === 'TAG_REQUIRED' && (
                                    <MenuItem
                                        onClick={async () => {
                                            await generateTag(row.originalLineItem);
                                        }}
                                        icon={
                                            <Center>
                                                <Icon as={RepeatIcon} boxSize="1.5rem"/>
                                            </Center>
                                        }
                                    >
                                        {content.existing.table.tooltip.generateTag}
                                    </MenuItem>
                                )}
                                {row.status === 'READY' && (
                                    <MenuItem
                                        onClick={() => {
                                            navigator.clipboard.writeText(row.originalLineItem.tag!).then(r => undefined);
                                        }}
                                        icon={
                                            <Center>
                                                <Icon as={CopyIcon} boxSize="1.5rem"/>
                                            </Center>
                                        }
                                    >
                                        {content.existing.table.tooltip.copyTag}
                                    </MenuItem>
                                )}
                            </MenuList>
                        </Menu>
                    </HStack>
                );
            },
        },
    ];

    const dataSource: Array<Row<DataRow>> = lineItems.map((lineItem) => {
        return {
            key: lineItem.id!,
            name: lineItem.name!,
            adFormat: lineItem.adFormat!,
            createdAt: new Date(lineItem.createdAt!),
            createdBy: getUserNameFromId(lineItem.createdBy),
            status: lineItem.status!,
            originalLineItem: lineItem
        };
    });

    const handleModelClose = () => {
        setIsLineItemVastModalOpen(false);
        setSelectedLineItem(undefined);
    };

    const renderNoLineItemsFound = (): ReactJSXElement => (
        <VStack>
            <Heading variant="amplifiedHeading">
                {content.existing.table.noEntries.heading}
            </Heading>
            <Text fontSize="sm">{content.existing.table.noEntries.subtitle}</Text>
        </VStack>
    );

    return (
        <>
            {selectedLineItem && (
                <Modal isOpen={isLineItemVastModalOpen} onClose={handleModelClose} size="5xl">
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>{content.existing.vast.title}</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <ProveLineItemVastModalBody selectedCampaign={selectedCampaign}
                                                        selectedLineItem={selectedLineItem}/>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
            <DataTable
                isPaginated
                defaultPageSize={10}
                dataSource={dataSource}
                columns={columns}
                noEntriesComponent={renderNoLineItemsFound()}
                onRow={(row) => ({
                    _hover: {
                        bgColor: 'gray.50',
                    },
                })}
            />
        </>
    );
};
