import {FeatureCode, LimitType} from '@api-clients/subscriptions';
import {
    Divider,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    HStack,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import {AmplifiedMinimisedLogo, NavBarButton} from '@components/atoms';
import {NavBarButtonProps} from '@components/atoms/navBarItem/NavBarButton';
import {routing} from '@configs';
import {
    BuildingOffice2Icon, CalendarIcon,
    ListBulletIcon,
    Square3Stack3DIcon,
    TableCellsIcon,
    UserGroupIcon,
} from '@heroicons/react/24/outline';
import {useManageMember} from '@hooks/organisations';
import {useAppContextHelper} from '@hooks/_contexts';
import {FC} from 'react';
import {shouldDisplayApiApplications} from '@shared/utils/formats/countryFeatures';
import {useSubscriptionHelper} from '@hooks/subscriptions';
import {usePosthogEvent} from '@hooks/_contexts/app/usePosthog';

export interface VerticalNavBarProps {
    onClose: () => void;
    isOpen: boolean;
}

export const VerticalNavbar: FC<VerticalNavBarProps> = ({onClose, isOpen}) => {
    const {
        currentContextValues: {organisation, user, featureBalances},
    } = useAppContextHelper();
    const {hasAdministrativePermission} = useManageMember();
    const {isAttentionDataOnly} = useSubscriptionHelper();
    const emitClickOnAttentionBenchmarkEvent = usePosthogEvent('Navigate to benchmarks');
    const emitClickOnCampaignsEvent = usePosthogEvent('Navigate to campaigns');

    const campaignsLabel = 'Campaigns';
    const attentionBenchmarkLabel = 'Attention benchmarks';
    const mainButtons: Array<NavBarButtonProps> = [
        {
            leftIcon: CalendarIcon,
            label: campaignsLabel,
            href: routing.campaign.root.path,
        },
        {
            leftIcon: TableCellsIcon,
            label: attentionBenchmarkLabel,
            href: routing.benchmarks.url,
        },
    ];

    const shouldDisplayApiApplicationButton =
        hasAdministrativePermission(organisation!, user!) &&
        shouldDisplayApiApplications(featureBalances!);

    const manageButtons: Array<NavBarButtonProps> = [
        {
            label: 'Organisation',
            href: routing.manageOrganisation.url,
            leftIcon: BuildingOffice2Icon,
        },
        {
            label: 'Clients',
            href: routing.manageClients.url,
            leftIcon: UserGroupIcon,
        },
        ...(shouldDisplayApiApplicationButton
            ? [
                {
                    leftIcon: Square3Stack3DIcon,
                    label: 'API applications',
                    href: routing.manageApplications.url,
                },
            ]
            : []),
    ];

    return (
        <>
            <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay bgColor="transparent"/>
                <DrawerContent>
                    <DrawerHeader borderBottomWidth="1px">
                        <AmplifiedMinimisedLogo height={"2rem"}/>
                    </DrawerHeader>
                    <DrawerBody>
                        <Stack spacing="1rem">
                            <VStack width="100%" align="left">
                                {mainButtons.map((navButton) => (
                                    <NavBarButton
                                        key={navButton.href}
                                        onClick={() => {
                                            onClose();
                                            switch (navButton.label) {
                                                case attentionBenchmarkLabel:
                                                    emitClickOnAttentionBenchmarkEvent();
                                                    break;
                                                case campaignsLabel:
                                                    emitClickOnCampaignsEvent();
                                                    break;
                                                default:
                                            }
                                        }}
                                        {...navButton}
                                    />
                                ))}
                            </VStack>
                            <Divider/>
                            <VStack width="100%" align="left">
                                <HStack>
                                    <Text fontWeight="semibold" color="green.500">
                                        Manage
                                    </Text>
                                </HStack>
                                {manageButtons.map((navButton) => (
                                    <NavBarButton
                                        key={navButton.href}
                                        onClick={onClose}
                                        {...navButton}
                                    />
                                ))}
                            </VStack>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};
