import {
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
    Box,
    Spacer,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Badge,
} from '@chakra-ui/react';
import { customerLogos, getFullVersionImage } from '@assets/images';
import { FC } from 'react';
import {
    CheckCircleIcon,
    LockClosedIcon,
    PhoneIcon,
    ShoppingCartIcon,
} from '@heroicons/react/24/solid';
import {
    GlobeAsiaAustraliaIcon,
    UserIcon,
    UsersIcon,
    DocumentPlusIcon,
    TableCellsIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { urls } from '@configs';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';

interface GetFullVersionModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const PerMediaPlanLabel: FC = () => <Text>per media plan</Text>;
const MediaPlansLabel: FC<{ numberOfPlans: number }> = ({ numberOfPlans }) => (
    <Text>{`${numberOfPlans} media plan${numberOfPlans > 1 ? 's' : ''}`}</Text>
);
const UnlimitedMediaPlanLabel: FC = () => (
    <Box>
        <Text fontWeight="700" as="span">
            Unlimited{' '}
        </Text>
        <Text as="span">media plans</Text>
    </Box>
);

export const GetFullVersionModal: FC<GetFullVersionModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation('freemium');
    const features = [
        t('features.0'),
        t('features.1'),
        t('features.2'),
        t('features.3'),
        t('features.4'),
        t('features.5'),
        t('features.6'),
    ];
    const plans = [
        {
            label: t('plans.payg.label'),
            pricing: t('plans.payg.pricing'),
            subscriptionLabel: t('plans.payg.subscriptionLabel'),
            mediaPlanLabel: <PerMediaPlanLabel />,
            numberOfUsers: 1,
            numberOfMarkets: 1,
            popular: false,
            checkoutLink: urls.checkOutLinkPayg,
            benchmarks: false,
        },
        {
            label: t('plans.standard.label'),
            pricing: t('plans.standard.pricing'),
            subscriptionLabel: t('plans.standard.subscriptionLabel'),
            mediaPlanLabel: <MediaPlansLabel numberOfPlans={20} />,
            numberOfUsers: 1,
            numberOfMarkets: 1,
            popular: false,
            checkoutLink: urls.checkOutLinkStandard,
            benchmarks: true,
        },
        {
            label: t('plans.professional.label'),
            pricing: t('plans.professional.pricing'),
            subscriptionLabel: t('plans.professional.subscriptionLabel'),
            mediaPlanLabel: <UnlimitedMediaPlanLabel />,
            numberOfUsers: 3,
            numberOfMarkets: 1,
            callToAction: false,
            popular: false,
            checkoutLink: urls.checkOutLinkProfessional,
            benchmarks: true,
        },
        {
            label: t('plans.advanced.label'),
            pricing: t('plans.advanced.pricing'),
            subscriptionLabel: t('plans.advanced.subscriptionLabel'),
            mediaPlanLabel: <UnlimitedMediaPlanLabel />,
            numberOfUsers: 10,
            numberOfMarkets: 1,
            popular: true,
            checkoutLink: urls.checkOutLinkProfessional,
            benchmarks: true,
        },
    ] as const;
    const emitClickOnPaygPackageEvent = usePosthogEvent('Click on PAYG upgrade');
    const emitClickOnStandardPackageEvent = usePosthogEvent('Click on Standard upgrade');
    const emitClickOnProfessionalPackageEvent = usePosthogEvent('Click on Professional upgrade');
    const emitClickOnAdvancedPackageEvent = usePosthogEvent('Click on Advanced upgrade');
    return (
        <Modal size="5xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
            <ModalOverlay />
            <ModalContent paddingY="2rem">
                <ModalHeader paddingTop="0" paddingBottom="2rem">
                    <Text
                        textAlign="center"
                        color="gray.700"
                        fontWeight={700}
                        lineHeight="1.5rem"
                        // fontSize="1rem"
                    >
                        Choose a plan that&apos;s right for you
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody padding="0">
                    <Card
                        overflow="hidden"
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="0.625rem"
                        shadow="lg"
                        marginX="3.5rem"
                    >
                        <Grid templateColumns="repeat(4, 1fr)" justifyContent="center">
                            {plans.map((plan, index) => (
                                <GridItem position="relative" key={plan.label}>
                                    <Card
                                        border="1px solid transparent"
                                        borderColor="transparent"
                                        borderRight={
                                            index === plans.length - 1 ? 'none' : '1px solid'
                                        }
                                        borderRightColor="gray.200"
                                        borderTopLeftRadius={index === 0 ? '0.625rem' : '0'}
                                        borderTopRightRadius={
                                            index === plans.length - 1 ? '0.625rem' : '0'
                                        }
                                        borderBottomLeftRadius={index === 0 ? '0.625rem' : '0'}
                                        borderBottomRightRadius={
                                            index === plans.length - 1 ? '0.625rem' : '0'
                                        }
                                    >
                                        <CardHeader
                                            display="flex"
                                            flexDirection="column"
                                            gap="1.5rem"
                                            position="relative"
                                        >
                                            <Box>
                                                <Text
                                                    fontWeight="bold"
                                                    fontSize="0.875rem"
                                                    lineHeight="1.25rem"
                                                    color="gray.500"
                                                >
                                                    {plan.label}
                                                </Text>
                                            </Box>
                                            <Box>
                                                <Text
                                                    color="gray.700"
                                                    fontSize="0.875rem"
                                                    lineHeight="1.25rem"
                                                    fontWeight={700}
                                                >
                                                    {plan.pricing}
                                                </Text>
                                            </Box>
                                            <Box height="0.8rem">
                                                {plan.subscriptionLabel.length > 0 && (
                                                    <Text
                                                        fontSize="0.75rem"
                                                        fontWeight={700}
                                                        color="gray.500"
                                                    >
                                                        {plan.subscriptionLabel}
                                                    </Text>
                                                )}
                                            </Box>
                                            {plan.popular && (
                                                <Box
                                                    display="inline-flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    fontSize="0.75rem"
                                                    fontWeight={700}
                                                    lineHeight="1rem"
                                                    right="1.25rem"
                                                    top="1.35rem"
                                                    position="absolute"
                                                    paddingY="0rem"
                                                    paddingX="0.25rem"
                                                    borderRadius="0.3125rem"
                                                    backgroundColor="orange.500"
                                                    color="white"
                                                >
                                                    Most popular
                                                </Box>
                                            )}
                                        </CardHeader>
                                        <CardBody height="25rem">
                                            <Grid
                                                templateRows="repeat(4, 1fr)"
                                                gap="0.5rem"
                                                fontSize="0.75rem"
                                                lineHeight="1rem"
                                                fontWeight={500}
                                            >
                                                <GridItem>
                                                    <Flex gap="0.25rem" alignItems="center">
                                                        <Box width="0.8125rem" height="0.8125rem">
                                                            <DocumentPlusIcon />
                                                        </Box>
                                                        {plan.mediaPlanLabel}
                                                    </Flex>
                                                </GridItem>
                                                <GridItem>
                                                    <Flex gap="0.25rem" alignItems="center">
                                                        <Box width="0.8125rem" height="0.8125rem">
                                                            {plan.numberOfUsers > 1 ? (
                                                                <UsersIcon />
                                                            ) : (
                                                                <UserIcon />
                                                            )}
                                                        </Box>
                                                        {plan.numberOfUsers} user
                                                        {`${plan.numberOfUsers > 1 ? 's' : ''}`}
                                                    </Flex>
                                                </GridItem>
                                                <GridItem>
                                                    <Flex gap="0.25rem" alignItems="center">
                                                        <Box width="0.8125rem" height="0.8125rem">
                                                            <GlobeAsiaAustraliaIcon />
                                                        </Box>
                                                        {plan.numberOfMarkets} market
                                                        {`${plan.numberOfMarkets > 1 ? 's' : ''}`}
                                                    </Flex>
                                                </GridItem>
                                                <GridItem>
                                                    {plan.benchmarks && (
                                                        <Flex gap="0.25rem" alignItems="center">
                                                            <Box
                                                                width="0.8125rem"
                                                                height="0.8125rem"
                                                            >
                                                                <TableCellsIcon />
                                                            </Box>
                                                            Attention benchmarks
                                                            <Text
                                                                color="green"
                                                                marginRight="0.25rem"
                                                            >
                                                                New
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                </GridItem>
                                            </Grid>
                                        </CardBody>
                                        <CardFooter>
                                            <Button
                                                onClick={() => {
                                                    switch (plan.label) {
                                                        case t('plans.payg.label'):
                                                            emitClickOnPaygPackageEvent();
                                                            break;
                                                        case t('plans.standard.label'):
                                                            emitClickOnStandardPackageEvent();
                                                            break;
                                                        case t('plans.professional.label'):
                                                            emitClickOnProfessionalPackageEvent();
                                                            break;
                                                        case t('plans.advanced.label'):
                                                            emitClickOnAdvancedPackageEvent();
                                                            break;
                                                        default:
                                                    }
                                                    window.open(plan.checkoutLink, '_blank');
                                                }}
                                                borderRadius="0.125rem"
                                                backgroundColor={
                                                    plan.popular ? 'green.500' : 'white'
                                                }
                                                color={plan.popular ? 'white' : 'green.500'}
                                                border="1px solid"
                                                borderColor="green.600"
                                                width="100%"
                                                _hover={{
                                                    backgroundColor: plan.popular
                                                        ? 'green.700'
                                                        : 'green.500',
                                                    color: 'white',
                                                }}
                                            >
                                                <Text
                                                    fontWeight={600}
                                                    fontSize="0.75rem"
                                                    lineHeight="1rem"
                                                >
                                                    Upgrade
                                                </Text>
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                    {plan.popular && (
                                        <Box
                                            pointerEvents="none"
                                            position="absolute"
                                            inset={0}
                                            boxShadow="rgb(255, 255, 255) 0px 0px 0px 0px inset, #4E936F 0px 0px 0px 3px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px"
                                            borderTopLeftRadius={index === 0 ? '0.625rem' : '0'}
                                            borderBottomLeftRadius={index === 0 ? '0.625rem' : '0'}
                                            borderTopRightRadius={
                                                index === plans.length - 1 ? '0.625rem' : '0'
                                            }
                                            borderBottomRightRadius={
                                                index === plans.length - 1 ? '0.625rem' : '0'
                                            }
                                        />
                                    )}
                                </GridItem>
                            ))}
                        </Grid>
                    </Card>
                    <Box
                        marginTop="0.5rem"
                        paddingTop="0.5625rem"
                        paddingBottom="1.1875rem"
                        display="flex"
                        justifyContent="center"
                    >
                        <Text
                            textAlign="center"
                            lineHeight="1.5rem"
                            fontSize="0.8125rem"
                            color="gray.700"
                        >
                            or{' '}
                            <Link
                                color="green.500"
                                href={urls.bookACall}
                                textDecoration="underline"
                            >
                                book a demo
                            </Link>{' '}
                            with us.
                        </Text>
                    </Box>
                    <Divider />
                    <Box
                        marginTop="1.2rem"
                        marginBottom="1.4rem"
                        fontSize="0.875rem"
                        fontWeight={400}
                        lineHeight={1.25}
                        color="gray.700"
                    >
                        <Text textAlign="center" fontWeight={700} lineHeight={1.5}>
                            What&apos;s included in full-featured attentionPLAN®?
                        </Text>
                        {/** TODO: add all the clauses */}
                        <Box marginTop="1rem" display="flex" justifyContent="center">
                            <Flex flexDirection="column" gap="1rem">
                                {features.map((feature) => (
                                    <Flex
                                        direction="row"
                                        gap="0.75rem"
                                        fontSize="0.875rem"
                                        lineHeight="1.25rem"
                                        key={feature}
                                    >
                                        <Box width="1.5rem" height="1.5rem" color="green.500">
                                            <CheckCircleIcon />
                                        </Box>
                                        <Text>{feature}</Text>
                                    </Flex>
                                ))}
                            </Flex>
                        </Box>
                    </Box>
                    <Divider />
                    <Box
                        marginTop="1.1rem"
                        color="gray.700"
                        lineHeight="1.5rem"
                        marginBottom="1.5rem"
                    >
                        <Box>
                            <Text textAlign="center" fontSize="0.875rem" fontWeight={700}>
                                Used and trusted by
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            gap="1.6rem"
                            flexWrap="wrap"
                            justifyContent="center"
                            alignItems="center"
                            marginTop="1rem"
                        >
                            {/** Client Logos Place holders */}
                            {customerLogos.map((item) => (
                                <Image
                                    key={item.alt}
                                    width="4.375rem"
                                    objectFit="cover"
                                    src={item.src}
                                    alt={item.alt}
                                />
                            ))}
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
