export const sectionLabels = {
    accountDetails: {
        heading: 'Basic information',
        buttonName: {
            viewMode: 'Edit',
            editMode: 'Save',
        },
    },
    auth: {
        changePassword: {
            heading: 'Change password',
            buttonName: 'Request change password',
        },
        confirmForgotPassword: {
            heading: 'Confirmation code',
            subHeading:
                'A confirmation code has been sent to your email. Please enter the code and new password to reset.',
            buttonName: 'Confirm reset password',
        },
        forgotPassword: {
            heading: 'Forgot your password',
            subHeading: 'Enter your email below and we will send a message to reset your password.',
            buttonName: 'Send request',
        },
        login: {
            heading: 'Sign in to your account',
            subLinks: {
                forgotPassword: 'Forgot your password?',
            },
            buttonName: 'Sign in',
        },
        newPasswordRequired: {
            heading: 'Reset your password',
            subHeading: 'You are required to change your password after first login.',
            buttonName: 'Reset password',
        },
        resetPasswordSuccess: {
            heading: 'Success password reset',
            subHeading: {
                auth0: 'A reset password request has been sent to your email. Please follow the instruction to reset your password.',
                cognito:
                    'Your password has been reset successfully. You now can login with the new password.',
            },
            buttonName: 'Sign in',
        },
    },
    manageMembers: {
        heading: 'Manage organisation members',
        buttonName: 'Add new member',
    },
    manageAdClients: {
        heading: 'Manage client profiles',
        buttonName: 'Add new client',
    },
    organisationDetails: {
        heading: 'Manage organisation details',
        buttonName: {
            viewMode: 'Edit organisation details',
            editMode: 'Save organisation details',
        },
    },
    manageIntegrations: {
        heading: 'Manage integration application',
        button: { create: 'Create new integration' },
    },
    mediaPlanService: {
        heading: 'Manage media plans service',
        button: { purchaseMore: 'Purchase more' },
    },
    mediaPlanApiService: {
        heading: 'Manage API service',
        button: { purchaseMore: 'Purchase more' },
    },
};
