import { FC, ForwardedRef, forwardRef, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { GetAttentionDataResponseDto } from '@api-clients/attention-data';
import { Box, useToken } from '@chakra-ui/react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { toLocaleFixed } from '@shared/utils';
import { SortDirection } from '@shared/cores';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTranslation } from 'react-i18next';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Chart, Plugin } from 'chart.js';

interface AttentionMetricsChartProps {
    isPaidPlan: boolean;
    attentionData: Array<GetAttentionDataResponseDto>;
    sortBy?: string;
    sortDirection?: SortDirection;
    boxHeight?: number | string;
    noEntriesComponent?: ReactJSXElement;
    id?: string;
    responsive?: boolean;
    showLegend?: boolean;
    animated?: boolean;
    hidden?: boolean;
    height?: number | string;
    width?: string | number;
}

export const AttentionMetricsChart = forwardRef<
    ChartJSOrUndefined<'bar', string[], object>,
    AttentionMetricsChartProps
>(
    (
        {
            isPaidPlan,
            attentionData,
            sortBy,
            sortDirection = 1,
            boxHeight = 'auto',
            noEntriesComponent,
            id,
            responsive = true,
            showLegend = true,
            animated = true,
            hidden = false,
            width = 'auto',
            height = 'auto',
        },
        ref,
    ) => {
        const chartColors = useToken('colors', ['green.500', 'orange.500', 'gray.400']);

        const { t } = useTranslation('benchmark');

        const lockTooltip = t('attentionBenchmarks.lockTooltip', {
            returnObjects: true,
        });

        attentionData.sort((a: any, b: any) => {
            if (sortBy === 'none') {
                if (
                    `${a.adChannel?.name} - ${a.adFormat?.name}` >
                    `${b.adChannel?.name} - ${b.adFormat?.name}`
                )
                    return sortDirection === 1 ? 1 : -1;
                if (
                    `${a.adChannel?.name} - ${a.adFormat?.name}` <
                    `${b.adChannel?.name} - ${b.adFormat?.name}`
                )
                    return sortDirection === 1 ? -1 : 1;
            }
            if (sortBy !== 'none' && sortBy !== 'totalAttention') {
                if (sortDirection === 1) return a[sortBy ?? ''] - b[sortBy ?? ''];
                if (sortDirection === -1) return b[sortBy ?? ''] - a[sortBy ?? ''];
            }
            if (sortBy === 'totalAttention')
                if (sortDirection === 1)
                    return (
                        a['averageActiveAttentionSeconds'] +
                        a['averagePassiveAttentionSeconds'] -
                        (b['averageActiveAttentionSeconds'] + b['averagePassiveAttentionSeconds'])
                    );
            if (sortDirection === -1)
                return (
                    b['averageActiveAttentionSeconds'] +
                    b['averagePassiveAttentionSeconds'] -
                    (a['averageActiveAttentionSeconds'] + a['averagePassiveAttentionSeconds'])
                );
            return 0;
        });

        const breakStringIntoArray = (inputString: string, maxLength: number): string[] => {
            const words = inputString.split(' ');
            const result: string[] = [];
            let currentLine = '';

            // eslint-disable-next-line no-restricted-syntax
            for (const word of words) {
                if (currentLine.length + word.length <= maxLength) {
                    currentLine += (currentLine ? ' ' : '') + word;
                } else {
                    result.push(currentLine);
                    currentLine = word;
                }
            }

            if (currentLine) {
                result.push(currentLine);
            }

            return result;
        };

        const totalAttention = [
            ...attentionData.map((a) => a.averageActiveAttentionSeconds ?? 0),
            ...attentionData.map((a) => a.averagePassiveAttentionSeconds ?? 0),
        ];
        const labels: object[] = attentionData.map(
            (ad) => breakStringIntoArray(`${ad.adChannel?.name} - ${ad.adFormat?.name}`, 28) ?? '',
        );
        const averageActiveAttentionData: string[] = attentionData.map((ad, index) =>
            isPaidPlan || index < 1
                ? toLocaleFixed(ad.averageActiveAttentionSeconds ?? 0, 1)
                : toLocaleFixed(Math.max(...totalAttention), 1),
        );
        const averagePassiveAttentionData: string[] = attentionData.map((ad, index) =>
            isPaidPlan || index < 1
                ? toLocaleFixed(ad.averagePassiveAttentionSeconds ?? 0, 1)
                : toLocaleFixed(Math.max(...totalAttention), 1),
        );

        const chartData = {
            labels,
            datasets: [
                {
                    label: 'Average Active Attention Seconds',
                    data: averageActiveAttentionData,
                    backgroundColor: (color: any) => {
                        if (isPaidPlan) return chartColors[0];
                        if (color.index >= 1) return chartColors[2];
                        return chartColors[0];
                    },
                },
                {
                    label: 'Average Passive Attention Seconds',
                    data: averagePassiveAttentionData,
                    backgroundColor: (color: any) => {
                        if (isPaidPlan) return chartColors[1];
                        if (color.index >= 1) return chartColors[2];
                        return chartColors[1];
                    },
                },
            ],
        };
        const setChartBackgroundPlugin: Plugin<'bar'> = {
            id: 'setBackgroundToWhitePlugin',
            beforeDraw: (chartCtx: Chart) => {
                const ctx = chartCtx.canvas.getContext('2d');
                if (!ctx) {
                    return;
                }
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
                ctx.restore();
            },
        };

        return (
            <Box height={boxHeight} hidden={hidden}>
                {attentionData.length > 0 ? (
                    <Bar
                        id={id}
                        height={height}
                        width={width}
                        updateMode="active"
                        data={chartData}
                        options={{
                            responsive,
                            animation: animated ? undefined : false,
                            indexAxis: 'y',
                            scales: {
                                x: {
                                    beginAtZero: true,
                                    grace: '10%',
                                },
                                y: {
                                    afterFit(scaleInstance) {
                                        scaleInstance.width = 250;
                                    },
                                },
                            },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        title(e: any) {
                                            return isPaidPlan || e[0].dataIndex < 1
                                                ? e[0].label.replaceAll(',', ' ')
                                                : lockTooltip;
                                        },
                                        label(e: any) {
                                            return isPaidPlan || e.dataIndex < 1
                                                ? `${e.dataset.label}: ${e.formattedValue}`
                                                : lockTooltip;
                                        },
                                    },
                                },
                                legend: { position: 'top', reverse: false, display: showLegend },
                                datalabels: {
                                    display: true,
                                    color: 'black',
                                    anchor: 'end',
                                    align: 'start',
                                    offset: -45,
                                    font: { weight: 'bold' },
                                    formatter(value, context) {
                                        if (isPaidPlan) return `${value}s`;
                                        if (context.dataIndex < 1) return `${value}s`;
                                        return '';
                                    },
                                },
                            },
                        }}
                        plugins={[ChartDataLabels, setChartBackgroundPlugin]}
                        ref={ref}
                    />
                ) : (
                    <Box padding="2rem">{noEntriesComponent}</Box>
                )}
            </Box>
        );
    },
);
