import { Box, Button, Center, Icon, Spinner, Text, VStack } from '@chakra-ui/react';
import { DocumentArrowUpIcon, DocumentCheckIcon } from '@heroicons/react/24/solid';
import { createRef, FC, useCallback, useMemo, useState } from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
    fileToRowArray,
    frequencyValueCheck,
    isAtLeastTwoNonZeroRows,
    isTemplateFileValid,
    totalAudienceCheck,
} from './ValidateImportTemplate';

// see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export enum mimeType {
    xls = 'application/vnd.ms-excel',
    xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export interface FileUploadContainerProps {
    maxFiles?: number;
    acceptedFileTypes?: Array<mimeType>;
    isMediaImportTemplate?: boolean;
    onFileUpload: (files: Array<File>) => void;
}

type FileUploadState = 'initial' | 'loading' | 'success' | 'failure';

export const FileUploadContainer: FC<FileUploadContainerProps> = ({
    maxFiles,
    acceptedFileTypes,
    onFileUpload,
    isMediaImportTemplate = false, // flag to use excel file validator
}) => {
    const { t } = useTranslation('mediaPlans');
    const errorMessageVariants = useMemo(() => {
        return {
            maxFilesExceeded: `${t(
                'mediaPlanning.campaignCreation.errorMessages.maxFilesExceeded',
                { count: maxFiles },
            )}`,
            invalidFileType: `${t('mediaPlanning.campaignCreation.errorMessages.invalidFileType', {
                fileFormats: Object.keys(mimeType)
                    .map((format) => format.toUpperCase())
                    .join(', '),
            })}`,
            invalidFileValues: `${t(
                'mediaPlanning.campaignCreation.errorMessages.invalidFileValues',
            )}`,
            doesNotHaveAtLeastTwoNonZeroRows: `${t(
                'mediaPlanning.campaignCreation.errorMessages.doesNotHaveAtLeastTwoNonZeroRows',
            )}`,
            totalAudienceMustLargerThanTargetAudience: `${t(
                'mediaPlanning.campaignCreation.errorMessages.totalAudienceMustLargerThanTargetAudience',
            )}`,
            frequencyShouldBeMoreThanOrEqualToOne: `${t(
                'mediaPlanning.campaignCreation.errorMessages.frequencyShouldBeMoreThanOrEqualToOne',
            )}`,
        };
    }, [maxFiles]);

    const [dropzoneState, setDropzoneState] = useState<FileUploadState>('initial');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const isMaxFilesExceeded = useCallback(
        (files: Array<File>): boolean => !!maxFiles && maxFiles > 0 && files.length > maxFiles,
        [maxFiles],
    );

    const isFileTypeInvalid = useCallback(
        (files: Array<File>): boolean =>
            !!acceptedFileTypes &&
            !files.every((file) => acceptedFileTypes.includes(file.type as mimeType)),
        [acceptedFileTypes],
    );

    const dropzoneRef = createRef<DropzoneRef>();
    const openDialog = () => {
        // Note that the ref is set async,
        // so it might be null at some point
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const verifyFiles = useCallback(
        async (files: Array<File>): Promise<string> => {
            if (isMaxFilesExceeded(files)) {
                return errorMessageVariants.maxFilesExceeded;
            }
            if (isFileTypeInvalid(files)) {
                return errorMessageVariants.invalidFileType;
            }
            if (isMediaImportTemplate) {
                const promises = [];
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    promises.push(fileToRowArray(file));
                }

                const data = await Promise.all(promises);
                let error = '';
                data.forEach((item) => {
                    if (!isTemplateFileValid(item)) {
                        error = errorMessageVariants.invalidFileValues;
                    }

                    if (!isAtLeastTwoNonZeroRows(item)) {
                        error = errorMessageVariants.doesNotHaveAtLeastTwoNonZeroRows;
                    }

                    if (!totalAudienceCheck(item)) {
                        error = errorMessageVariants.totalAudienceMustLargerThanTargetAudience;
                    }

                    if (!frequencyValueCheck(item)) {
                        error = errorMessageVariants.frequencyShouldBeMoreThanOrEqualToOne;
                    }
                });
                return error;
            }
            return '';
        },
        [isMaxFilesExceeded, errorMessageVariants, isFileTypeInvalid, isMediaImportTemplate],
    );

    const handleOnDrop = async (files: Array<File>) => {
        setDropzoneState('loading');

        const error = await verifyFiles(files);
        setErrorMessage(error);
        if (error === '') {
            onFileUpload(files);
            setDropzoneState('success');
        } else {
            onFileUpload([]);
            setDropzoneState('failure');
        }
    };

    const fileIcon = useMemo(() => {
        const iconSize = '36px';
        switch (dropzoneState) {
            case 'success':
                return <Icon color="green.500" boxSize={iconSize} as={DocumentCheckIcon} />;
            case 'loading':
                return <Spinner boxSize={iconSize} />;
            default:
                return <Icon color="gray.500" boxSize={iconSize} as={DocumentArrowUpIcon} />;
        }
    }, [dropzoneState]);

    return (
        <Dropzone ref={dropzoneRef} onDrop={handleOnDrop} noClick noKeyboard>
            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                <Center
                    position="relative"
                    bgColor={dropzoneState === 'failure' ? 'red.50' : 'gray.50'}
                    borderRadius="lg"
                    border="1px dashed"
                    borderColor={isDragActive ? 'gray.400' : 'gray.200'}
                    style={{ width: '100%', height: '100%' }}
                    p="4rem"
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <VStack>
                        {fileIcon}
                        <Text>Drag and drop your file here</Text>
                        <Button onClick={openDialog} variant="link" colorScheme="blue">
                            Browse Files
                        </Button>
                    </VStack>
                    <Box left="1.5rem" bottom="1rem" position="absolute">
                        {acceptedFiles.length > 0 && (
                            <VStack align="left" spacing={0}>
                                <Text fontSize="sm" color="gray.700">
                                    {acceptedFiles.map((file) => file.name).join(', ')}
                                </Text>

                                {errorMessage === '' ? (
                                    <Text fontSize="xs" color="green">
                                        File uploaded successfully
                                    </Text>
                                ) : (
                                    <Text fontSize="xs" color="red">
                                        {errorMessage}
                                    </Text>
                                )}
                            </VStack>
                        )}
                    </Box>
                </Center>
            )}
        </Dropzone>
    );
};
