import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Stack,
    Text,
    useDisclosure,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { FC } from 'react';

interface Channel {
    channelCode: string;
    channelName: string;
    formats: Array<Format>;
}

interface Format {
    formatCode: string;
    formatName: string;
}

interface AdFormatsConstraintModalProps {
    channels?: Array<Channel>;
}

export const AdFormatDetailModal: FC<AdFormatsConstraintModalProps> = ({ channels }) => {
    const formats = channels?.flatMap((ch) => ch.formats) ?? [];

    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
        <>
            <Text variant="premium">Formats</Text>

            <Button
                loadingText="Loading formats..."
                isLoading={!channels}
                onClick={onOpen}
                variant="link"
            >{`${channels?.length} Channels ${formats.length} Formats`}</Button>

            <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ad Formats</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack>
                            {channels
                                ?.sort((a, b) => a.channelName.localeCompare(b.channelName))
                                .map((ch) => {
                                    return (
                                        <Box key={ch.channelCode}>
                                            <Text fontWeight="semibold">{ch.channelName}</Text>
                                            <Wrap gap="0.2rem">
                                                {ch.formats
                                                    .sort((a, b) =>
                                                        a.formatName.localeCompare(b.formatName),
                                                    )
                                                    .map((f) => f.formatName)
                                                    .join(', ')}
                                            </Wrap>
                                        </Box>
                                    );
                                })}
                        </Stack>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
        </>
    );
};
