import { ComponentStyleConfig } from '@chakra-ui/react';
import { premiumTextStyling } from '../text/Text';

export const FormLabel: ComponentStyleConfig = {
    baseStyle: {
        ...premiumTextStyling,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
};
