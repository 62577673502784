/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ApiService = {
    AccountManager: 'account_manager',
    AttentionData: 'attention_data',
    MediaPlans: 'media_plans',
    AttentionTrade: 'attention_trade',
    Subscriptions: 'subscriptions',
    AttentionProve: 'attention_prove',
    ReachCurve: 'reach_curve'
} as const;
export type ApiService = typeof ApiService[keyof typeof ApiService];


export function ApiServiceFromJSON(json: any): ApiService {
    return ApiServiceFromJSONTyped(json, false);
}

export function ApiServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiService {
    return json as ApiService;
}

export function ApiServiceToJSON(value?: ApiService | null): any {
    return value as any;
}

